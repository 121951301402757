import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import LoadingSpinner from "../core/loading-spinner";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import {Dialog, Table, TableBody, TextField, Typography} from "@material-ui/core";
import FoodEntry from "../food-entry/Food-entry";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import FoodList from "./Food-list-connected";
import Divider from "@material-ui/core/Divider";
import {Link, useNavigate, useParams} from "react-router-dom";
import FoodEntriesNutrition from "../food-entries-nutrition/Food-entries-nutrition";
import CopyMealToMealPlan from "./copy-meal-to-meal-plan-connected";
import CopyMealToMealPlanTemplate from "./copy-meal-to-meal-plan-template-connected";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import teal from "@material-ui/core/es/colors/teal";
import green from "@material-ui/core/es/colors/green";

const styles = theme => ({
    root: {
        position: 'relative',
        padding: 16,
        maxWidth: 700,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    card: {
        padding: '10px 20px',
    },
    flexGrid: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    link: {
        textDecoration: 'none',
    },
    dialog: {
        maxWidth: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    dialogHeader: {
        padding: '15px 20px 10px',
        position: 'sticky',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: teal[600],
        textTransform: 'uppercase',
        zIndex: 2,
    },
    whiteColor: {
        color: 'white',
    },
    dialogContent: {
        padding: 20,
    },
    closeButton: {
        position: 'absolute',
        top: 4,
        right: 4,
        color: 'white',
    },
    snackbarContent: {
        backgroundColor: green[600],
    },
    snackbarIcon: {
        fontSize: 20,
    },
    headline: {
        cursor: 'pointer',
        marginTop: 10,
    },
    nameForm: {
        display: 'flex',
        marginBottom: 20,
        position: 'relative',
    },
    nameFormTextField: {
        flexGrow: 1,
        marginRight: 10,
    },
});

function Meal({
    name,
    food_entries = [],
    onLoad,
    onCleanup,
    onUpdateName,
    onCreateFoodEntries,
    onDeleteFoodEntry,
    onUpdateFoodEntry,
    onDelete,
    isLoading,
    classes,
}) {
    const [isEditingName, setIsEditingName] = useState(false);
    const [isSavingName, setIsSavingName] = useState(false);
    const [showAddFood, setShowAddFood] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (onLoad) {
            onLoad({ id });
        }
        return () => {
            if (onCleanup) {
                onCleanup();
            } 
        }
    }, [onLoad, id, onCleanup]);

    const handleToggleShowAddFood = () => {
        setShowAddFood((prev) => !prev);
    };

    const handleHideSnackbar = () => {
        setShowSnackbar(false);
    };

    const handleDelete = () => {
        onDelete({id}).then(() => {
            navigate('/maaltid');
        });
    }; 

    const handleToggleEditName = () => {
        setIsEditingName((prev) => !prev);
    };

    const handleUpdateName = (event) => {
        event.preventDefault();
        setIsSavingName(true);
        const name = event.target.name.value;
        onUpdateName({id, name}).then(() => {
            handleToggleEditName();
            setIsEditingName(false);
        });
    };

    const handleCreateFoodEntries = (foodEntries) => {
        onCreateFoodEntries({mealId: id, foodEntries}).then(() => {
            setShowSnackbar(true);
        });
    };

    return isLoading ? (
            <LoadingSpinner />
        ): (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card className={classes.card}>
                        {isEditingName
                            ? (
                                <form onSubmit={handleUpdateName} className={classes.nameForm}>
                                    {isSavingName && <LoadingSpinner />}
                                    <TextField
                                        autoFocus
                                        name={"name"}
                                        label={"Navn"}
                                        defaultValue={name}
                                        required
                                        className={classes.nameFormTextField}
                                    />
                                    <Button
                                        type={"submit"}
                                        color={"primary"}
                                        variant={"contained"}
                                    >
                                        Gem
                                    </Button>
                                </form>
                            ) : (
                                <Typography
                                    variant={"h5"}
                                    className={classes.headline}
                                    onClick={handleToggleEditName}
                                >
                                    {name}
                                </Typography>
                            )}
                        { food_entries.length > 0 && (
                            <Table padding="checkbox">
                                <TableBody>
                                    {food_entries.map((entry) => (
                                        <FoodEntry
                                            key={`foodentry${entry.id}`}
                                            id={entry.id}
                                            food={entry.food}
                                            food_measure_id={entry.food_measure_id}
                                            isMovable={false}
                                            weight={entry.weight}
                                            onDeleteFoodEntry={onDeleteFoodEntry}
                                            onUpdateFoodEntry={onUpdateFoodEntry}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        )}
                        <br/>
                        <Button
                            variant={"outlined"}
                            color={"primary"}
                            fullWidth
                            onClick={handleToggleShowAddFood}
                        >
                            Tilføj madvare
                        </Button>
                        <Dialog open={showAddFood} onClose={handleToggleShowAddFood} fullScreen className={classes.dialog}>
                            <div className={classes.dialogHeader}>
                                <IconButton aria-label="Close" className={classes.closeButton} onClick={handleToggleShowAddFood}>
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant={"h5"} className={classes.whiteColor}>Tilføj madvare</Typography>
                            </div>
                            <div className={classes.dialogContent}>
                                <FoodList onCreateFoodEntries={handleCreateFoodEntries} />
                            </div>
                        </Dialog>
                        <br/>
                        <br/>
                        <br/>
                        <Divider />
                        <br/>
                        <div className={classes.flexGrid}>
                            <Link to="/maaltid" className={classes.link}>
                                <Button
                                    variant={"outlined"}
                                >
                                    Tilbage
                                </Button>
                            </Link>
                            <Button
                                type="submit"
                                color={"secondary"}
                                variant={"outlined"}
                                onClick={handleDelete}
                            >
                                Slet måltid
                            </Button>
                        </div>
                    </Card>
                </Grid>
                <FoodEntriesNutrition foodEntries={food_entries}/>
                <CopyMealToMealPlan mealId={id} />
                <CopyMealToMealPlanTemplate mealId={id} />
            </Grid>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                variant={"success"}
                open={showSnackbar}
                autoHideDuration={6000}
                onClose={handleHideSnackbar}
            >
                <SnackbarContent className={classes.snackbarContent}
                     message={"Madvare tilføjet"}
                     action={[
                         <IconButton
                             key="Luk"
                             aria-label="Luk"
                             color="inherit"
                             onClick={handleHideSnackbar}
                         >
                             <CloseIcon className={classes.snackbarIcon} />
                         </IconButton>,
                     ]}
                />
            </Snackbar>
        </div>
    );

}

export default withStyles(styles)(Meal);
