import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

class LogOutButton extends Component {
    static props = {
        onLogOut: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Button variant={"outlined"} type="button" color={"inherit"} onClick={this.props.onLogOut}>Log ud</Button>
        );
    }
}

export default LogOutButton;
