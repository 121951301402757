import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import {Typography} from "@material-ui/core";
import {getFoodEntriesTotalNutrition, getFoodEntriesTotalWater} from "../../engine/nutrition";
import NutritionChart from "../nutrition/Nutrition-chart";
import NutritionTable from "../nutrition/nutrition-table";

const styles = theme => ({
    card: {
        padding: '10px 20px',
    },
});

class FoodEntriesNutrition extends Component {
    static props = {
        foodEntries: PropTypes.object.isRequired,
        showWater: PropTypes.bool,
        classes: PropTypes.object.isRequired,
    };

    static defaultProps = {
        foodEntries: [],
    };

    render() {
        const {
            classes,
            foodEntries,
            showWater,
        } = this.props;

        if (!foodEntries.length) {
            return null;
        }

        const {
            kcal,
            fat,
            protein,
            carbohydrates,
        } = getFoodEntriesTotalNutrition(foodEntries);

        const water = getFoodEntriesTotalWater(foodEntries);

        return (
            <Grid item xs={12}>
                <Card className={classes.card}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={3}>
                            <NutritionTable
                                heading={"Næringsindhold:"}
                                kcal={kcal}
                                fat={fat}
                                protein={protein}
                                carbohydrates={carbohydrates}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Typography variant={"caption"}>
                                Energifordeling:
                            </Typography>
                            <NutritionChart
                                fat={fat}
                                protein={protein}
                                carbohydrates={carbohydrates}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            {showWater && water !== 0 && (
                                <Typography variant="overline">
                                    Vand: {getFoodEntriesTotalWater(foodEntries)} ml
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        );
    }
}

export default withStyles(styles)(FoodEntriesNutrition);
