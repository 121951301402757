import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FoodList from '../food/Food-list';
import * as foodActions from '../food/food-actions';

function mapStateToProps(state) {
    const {
        food,
    } = state;
    return {
        food: food.list,
        isLoading: food.isLoading,
    };
}


const mapDispatchToProps = (dispatch) => {
    const {
        onSearch,
        onCleanup,
        onCreateFood,
    } = foodActions;
    return bindActionCreators({
        onSearch: onSearch,
        onCleanup: onCleanup,
        onCreateFood: onCreateFood,
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(FoodList);
