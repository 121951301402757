import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import {InputAdornment, Typography, withStyles, Paper} from "@material-ui/core";

const styles = theme => ({
    formButtonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    root: {
        padding: 20,
        marginTop: 20,
    }
});

class FoodMeasureCreator extends Component {
    static props = {
        foodId: PropTypes.number.isRequired,
        onCreateFoodMeasure: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired,
    };

    state = {
        name: '',
        namePlural: '',
        weight: '',
    };

    handleCreateFoodMeasure = (event) => {
        event.preventDefault();

        const {
            foodId,
            onCreateFoodMeasure,
            onClose,
        } = this.props;

        const {
            name,
            namePlural,
            weight,
        } = this.state;

        onCreateFoodMeasure({ foodId, weight, name, namePlural })
            .then(() => {
                this.setState({
                    name: '',
                    namePlural: '',
                    weight: '',
                });
                onClose();
            });
    };

    handleOnNameChange = (evt) => {
        this.setState({
            name: evt.target.value,
        });
    };

    handleOnNamePluralChange = (evt) => {
        this.setState({
            namePlural: evt.target.value,
        });
    };

    handleOnWeightChange = (evt) => {
        this.setState({
            weight: evt.target.value,
        });
    };

    render() {
        const {
            classes,
            onClose
        } = this.props;
        const {
            name,
            namePlural,
            weight,
        } = this.state;
        return (<Paper elevation={2} className={classes.root}>
            <Typography variant={"button"}>
                Opret ny mængde
            </Typography>
            <form onSubmit={this.handleCreateFoodMeasure}>
                <TextField
                    label={"Navn i ental (fx. skive)"}
                    type="text"
                    name="name"
                    required
                    fullWidth
                    value={name}
                    onChange={this.handleOnNameChange}
                />
                <TextField
                    label={"Navn i flertal (fx. skiver)"}
                    type="text"
                    name="namePlural"
                    required
                    fullWidth
                    value={namePlural}
                    onChange={this.handleOnNamePluralChange}
                />
                <TextField
                    label={"Vægt"}
                    type="number"
                    name="weight"
                    required
                    fullWidth
                    InputProps={{
                        endAdornment: <InputAdornment position="end">g</InputAdornment>,
                    }}
                    value={weight}
                    onChange={this.handleOnWeightChange}
                />
                <br/>
                <br/>
                <div className={classes.formButtonContainer}>
                    <Button type="button" variant="outlined" onClick={onClose}>Fortryd</Button>
                    <Button type="submit" variant="contained" color={"primary"}>Opret mængde</Button>
                </div>
            </form>
        </Paper>);
    }
}

export default withStyles(styles)(FoodMeasureCreator);
