export function foodEntryToWeight(foodEntry) {
    const { food_measure_id, weight, food } = foodEntry;
    const foodMeasure = food_measure_id ? food.measures.find(({id}) => id === food_measure_id) : undefined;

    return foodMeasure ? weight*foodMeasure.weight : weight;
}

export function foodEntryToKcal(foodEntry) {
    const { food } = foodEntry;
    const calculatedWeight = foodEntryToWeight(foodEntry);
    return (calculatedWeight*(food.kcal/100));
}

export function foodEntryToFat(foodEntry) {
    const { food } = foodEntry;
    const calculatedWeight = foodEntryToWeight(foodEntry);

    return (calculatedWeight*(food.fat/100));
}

export function foodEntryToProtein(foodEntry) {
    const { food } = foodEntry;
    const calculatedWeight = foodEntryToWeight(foodEntry);

    return (calculatedWeight*(food.protein/100));
}

export function foodEntryToCarbohydrates(foodEntry) {
    const { food } = foodEntry;
    const calculatedWeight = foodEntryToWeight(foodEntry);

    return (calculatedWeight*(food.carbohydrates/100));
}

export function getFoodEntryNutrition(foodEntry) {
    return {
        kcal: foodEntryToKcal(foodEntry),
        fat: foodEntryToFat(foodEntry),
        protein: foodEntryToProtein(foodEntry),
        carbohydrates: foodEntryToCarbohydrates(foodEntry),
        weight: foodEntryToWeight(foodEntry),
    }
}

export function getFoodEntriesTotalWater(foodEntries) {
    if(!foodEntries.length) {
        return 0;
    }
    const water = foodEntries.filter(({ food }) => food.name === "Vand").map((foodEntry) => foodEntryToWeight(foodEntry));
    return water.length ? water.reduce((total, number) => parseInt(total) + parseInt(number)) : 0;
}

export function getFoodEntriesTotalFat(foodEntries) {
    if(!foodEntries.length) {
        return 0;
    }
    const fat = foodEntries.map((foodEntry) => foodEntryToFat(foodEntry));
    return fat.length ? fat.reduce((total, number) => total + number) : 0;
}

export function getFoodEntriesTotalProtein(foodEntries) {
    if(!foodEntries.length) {
        return 0;
    }
    const protein = foodEntries.map((foodEntry) => foodEntryToProtein(foodEntry));
    return protein.length ? protein.reduce((total, number) => total + number) : 0;
}

export function getFoodEntriesTotalCarbohydrates(foodEntries) {
    if(!foodEntries.length) {
        return 0;
    }
    const carbohydrates = foodEntries.map((foodEntry) => foodEntryToCarbohydrates(foodEntry));
    return carbohydrates.length ? carbohydrates.reduce((total, number) => total + number) : 0;
}

export function getFoodEntriesTotalKcal(foodEntries) {
    if(!foodEntries.length) {
        return 0;
    }
    const kcal = foodEntries.map((foodEntry) => foodEntryToKcal(foodEntry));
    return kcal.length ? kcal.reduce((total, number) => total + number) : 0;
}

export function getFoodEntriesTotalNutrition(foodEntries) {
    return {
        kcal: getFoodEntriesTotalKcal(foodEntries),
        fat: getFoodEntriesTotalFat(foodEntries),
        protein: getFoodEntriesTotalProtein(foodEntries),
        carbohydrates: getFoodEntriesTotalCarbohydrates(foodEntries),
    }
}
