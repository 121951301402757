import React, {Component} from 'react';
import PropTypes from 'prop-types';
import MenuItem from "@material-ui/core/MenuItem";
import {TextField} from "@material-ui/core";
import LoadingSpinner from "../core/loading-spinner";

class TimeOfDayPicker extends Component {
    static props = {
        timeOfDayList: PropTypes.arrayOf({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        }),
        selectedTimeOfDayId: PropTypes.number,
        name: PropTypes.string,
        label: PropTypes.string,
        onChange: PropTypes.func,
        onLoad: PropTypes.func,
        isLoading: PropTypes.bool,
        onCleanup: PropTypes.func,
        classes: PropTypes.object.isRequired,
    };

    static defaultProps = {
        label: "Flyt til:",
        name: "timeOfDay",
        timeOfDayList: [],
    };

    componentDidMount() {
        const {
            onLoad,
        } = this.props;

        if (onLoad) {
            onLoad();
        }
    }

    componentWillUnmount() {
        const {
            onCleanup,
        } = this.props;

        if (onCleanup) {
            onCleanup();
        }
    }

    render() {
        const {
            timeOfDayList,
            label,
            name,
            selectedTimeOfDayId,
            onChange,
            isLoading,
        } = this.props;

        if (isLoading) {
            return (<div style={{position: 'relative', height: 48}}>
                <LoadingSpinner delay={1000} />
            </div>);
        }

        return (
            <TextField
                name={name}
                select
                value={selectedTimeOfDayId}
                margin={"none"}
                label={label}
                fullWidth
                onChange={onChange}
            >
                {timeOfDayList.map(({ id, name }) => (
                    <MenuItem value={id} key={id}>
                        {name}
                    </MenuItem>
                ))}
            </TextField>
        );
    }
}

export default TimeOfDayPicker;
