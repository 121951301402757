import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    withStyles,
    TextField,
    Hidden,
    InputAdornment,
} from '@material-ui/core';
import FoodMeasureCreator from '../food-measure/Food-measure-creator-connected';
/* eslint-disable react/jsx-no-duplicate-props */

const styles = theme => ({
    spaceBetween: {
      display: 'flex',
      justifyContent: 'space-between',
    },
});

class FoodEntryCreator extends Component {
    static props = {
        foodId: PropTypes.number.isRequired,
        measures: PropTypes.arrayOf({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            weight: PropTypes.number.isRequired,
        }),
        onCreateFoodEntries: PropTypes.func,
        classes: PropTypes.object.isRequired,
    };

    static defaultProps = {
        measures: [],
    };

    state = {
        showFoodMeasureCreator: false,
    };

    handleAddAmount = (event) => {
        event.preventDefault();

        const {
            measures,
            onCreateFoodEntries,
        } = this.props;

        const {
            weight: weightInput,
        } = event.target;

        const foodEntries = [];
        const weightSum = parseFloat(weightInput.value) || 0;
        if (weightSum > 0) {
            foodEntries.push({
                weight: weightSum,
            })
        }

        measures.forEach(({id: measureId}) => {
            const value = parseFloat(event.target[`${measureId}amount`].value) || 0;
            if (value > 0) {
                foodEntries.push({
                    food_measure_id: measureId,
                    weight: value,
                })
            }
        });

        onCreateFoodEntries(foodEntries);
    };

    handleToggleFoodMeasureCreator = () => {
        this.setState((prevState) => {
            return {
                showFoodMeasureCreator: !prevState.showFoodMeasureCreator,
            };
        });
    };

    render() {
        const {
            foodId,
            measures,
            classes,
        } = this.props;

        const {
            showFoodMeasureCreator,
        } = this.state;

        const unit = foodId === 14 ? 'kcal' : 'g';

        return (<Fragment>
            <form onSubmit={this.handleAddAmount}>
                <TextField
                    fullWidth
                    type="number"
                    name="weight"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
                    }}
                    inputProps={{
                        step: "any",
                        min: 0,
                    }}
                />
                {measures.sort((measure1, measure2) => measure1.weight - measure2.weight).map(({ id, name, weight }) => {
                    return (
                        <TextField
                            key={id}
                            label={`${name} (${weight}g)`}
                            fullWidth
                            type="number"
                            name={`${id}amount`}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">stk</InputAdornment>,
                            }}
                            inputProps={{
                                step: "any",
                                min: 0,
                            }}
                        />
                    );
                })}
                <br/>
                <br/>
                <div className={classes.spaceBetween}>
                    {showFoodMeasureCreator
                        ? <span/>
                        : (<React.Fragment>
                            <Hidden xsDown>
                                <Button type="button" variant="outlined" onClick={this.handleToggleFoodMeasureCreator}>
                                    Opret ny mængde
                                </Button>
                            </Hidden>
                            <Hidden smUp>
                                <span/>
                            </Hidden>
                        </React.Fragment>)
                    }
                    <Button type="submit" variant={"contained"} color={"primary"}>Tilføj</Button>
                </div>
            </form>
            {!showFoodMeasureCreator  && (<Fragment>
                <br />
                <Hidden smUp>
                    <Button type="button" variant="outlined" onClick={this.handleToggleFoodMeasureCreator} fullWidth>
                        Opret ny mængde
                    </Button>
                </Hidden>
            </Fragment>)}
            { showFoodMeasureCreator && <FoodMeasureCreator foodId={foodId} onClose={this.handleToggleFoodMeasureCreator} />}
            <br />
            <br />
        </Fragment>);
    }
}

export default withStyles(styles)(FoodEntryCreator);
