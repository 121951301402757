import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    withStyles,
} from '@material-ui/core';

const styles = theme => ({
    label: {
        fontSize: 12,
        fontFamily: theme.fontFamily,
    },
});

class YAxisTick extends Component {
    static props = {
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        classes: PropTypes.object.isRequired,
        payload: PropTypes.shape({
            value: PropTypes.number.isRequired,
        }).isRequired,
    };

    render() {
        const {
            classes,
            x,
            y,
            payload,
        } = this.props;
        return (
            <text x={x-15} y={y+4} className={classes.label}>
                {payload.value}
            </text>
        );
    }
}

export default withStyles(styles)(YAxisTick);
