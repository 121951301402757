import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles} from "@material-ui/core";
import ArrowUpIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownIcon from '@material-ui/icons/ArrowDownward';
import { numberToCommaString } from "../../helpers/format";

const styles = theme => ({
    arrow: {
        fontSize: '10px',
    }
});

class BodyMeasuresTextEntry extends Component {
    static props = {
        name: PropTypes.string,
        color: PropTypes.string,
        current: PropTypes.number,
        max: PropTypes.number,
        min: PropTypes.number,
        unit: PropTypes.string,
        classes: PropTypes.object.isRequired,
    };

    static defaultProps = {
        unit: '%',
    };


    render() {
        const {
            color,
            name,
            classes,
            current,
            max,
            min,
            unit,
        } = this.props;

        return (<Typography style={{color}} variant={"body2"}>
            {name}: {numberToCommaString(current)}{unit}&nbsp;
            <Typography variant={"caption"} style={{display: 'inline'}}>
                (<ArrowUpIcon className={classes.arrow}/>{numberToCommaString(max)}{unit}, <ArrowDownIcon className={classes.arrow}/>{numberToCommaString(min)}{unit})
            </Typography>
        </Typography>);
    }
}

export default withStyles(styles)(BodyMeasuresTextEntry);
