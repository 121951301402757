import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const styles = {
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
    },
    input: {
        marginLeft: 8,
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
};

function SearchInput(props) {
    const {
        classes,
        placeholder,
        onChange,
    } = props;

    return (
        <Paper className={classes.root} elevation={1}>
            <InputBase className={classes.input} placeholder={placeholder} onChange={onChange} fullWidth />
            <IconButton className={classes.iconButton} aria-label="Søg">
                <SearchIcon />
            </IconButton>
        </Paper>
    );
}

SearchInput.propTypes = {
    classes: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
};

export default withStyles(styles)(SearchInput);