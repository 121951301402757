import { connect } from 'react-redux';
import moment from "moment";
import BodyMeasuresText from './body-measures-text';

function mapStateToProps(state) {
    const {
        bodyMeasures,
    } = state;

    const hasData = bodyMeasures.measures && bodyMeasures.measures.length > 0;
    const currentMeasures = hasData ? bodyMeasures.measures.slice(0).sort((measure1, measure2) => moment(measure1.date).isAfter(moment(measure2.date)) ? -1 : 1)[0] : undefined;
    const currentWaterpercentage = currentMeasures ? currentMeasures.waterpercentage : undefined;
    const currentFatpercentage = currentMeasures ? currentMeasures.fatpercentage : undefined;
    const currentMusclemass = currentMeasures ? currentMeasures.musclemass : undefined;
    const maxWaterpercentage = hasData ? bodyMeasures.measures.slice(0).sort((measure1, measure2) => measure1.waterpercentage > measure2.waterpercentage ? -1 : 1)[0].waterpercentage : undefined;
    const minWaterpercentage = hasData ? bodyMeasures.measures.slice(0).sort((measure1, measure2) => measure1.waterpercentage < measure2.waterpercentage ? -1 : 1)[0].waterpercentage : undefined;
    const maxFatpercentage = hasData ? bodyMeasures.measures.slice(0).sort((measure1, measure2) => measure1.fatpercentage > measure2.fatpercentage ? -1 : 1)[0].fatpercentage : undefined;
    const minFatpercentage = hasData ? bodyMeasures.measures.slice(0).sort((measure1, measure2) => measure1.fatpercentage < measure2.fatpercentage ? -1 : 1)[0].fatpercentage : undefined;
    const maxMusclemass = hasData ? bodyMeasures.measures.slice(0).sort((measure1, measure2) => measure1.musclemass > measure2.musclemass ? -1 : 1)[0].musclemass : undefined;
    const minMusclemass = hasData ? bodyMeasures.measures.slice(0).sort((measure1, measure2) => measure1.musclemass < measure2.musclemass ? -1 : 1)[0].musclemass : undefined;

    return {
        currentWaterpercentage,
        maxWaterpercentage,
        minWaterpercentage,
        currentFatpercentage,
        maxFatpercentage,
        minFatpercentage,
        currentMusclemass,
        maxMusclemass,
        minMusclemass,
    };
}


export default connect(mapStateToProps)(BodyMeasuresText);
