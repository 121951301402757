import * as types from './action-types';
import api from '../../api';

export const onLoadTemplates = () => (dispatch) => {
    dispatch({
        type: types.UPDATE,
        payload: {
            isLoading: true,
        },
    });
    return api.get('mealPlanTemplate').then(({ data }) => {
        dispatch({
            type: types.UPDATE,
            payload: {
                list: data,
                isLoading: false,
            },
        });
    });
};

export const onLoadTemplate = ({ id }) => (dispatch) => {
    dispatch({
        type: types.UPDATE,
        payload: {
            isLoading: true,
        },
    });
    Promise.all([
        api.get(`mealPlanTemplate/${id}`),
        api.get('timeOfDay')]
    ).then((responses) => {
        dispatch({
            type: types.UPDATE,
            payload: {
                chosen: responses[0].data,
                isLoading: false,
                timeOfDayList: responses[1].data,
            },
        });
    });
};

export const onCreateTemplate = ({ name }) => (dispatch) => {
    dispatch({
        type: types.UPDATE,
        payload: {
            isLoading: true,
        },
    });
    const data = {
        name,
    };
    return api.post('mealPlanTemplate', data);
};

export const onUpdateTemplateName = ({ id, name }) => (dispatch) => {
    const data = {
        name,
    };

    return new Promise((resolve) => {
        api.put(`mealPlanTemplate/${id}`, data).then(() => {
            dispatch({
                type: types.UPDATE_TEMPLATE_NAME,
                name,
                id,
            });
            resolve();
        });
    });
};

export const onDeleteTemplate = ({ id }) => () => {
    return api.delete(`mealPlanTemplate/${id}`);
};

export const onCreateFoodEntries = (foodEntries) => (dispatch, getState) => {
    const state = getState();
    const mealPlanTemplateId = state.mealPlanTemplate.chosen.id;
    const data = {
        mealPlanTemplateId,
        foodEntries: foodEntries.map(({food, weight, food_measure_id, timeOfDayId}) => ({
            timeOfDayId,
            foodId: food.id,
            weight,
            food_measure_id,
        }))
    };
    return new Promise((resolve) => {
        api.post('mealPlanTemplateFoodEntries', data).then((response) => {
            dispatch({
                type: types.ADD_FOOD_ENTRIES,
                foodEntries: response.data,
            });
            resolve();
        });
    });
};

export const onDeleteFoodEntry = ({ id }) => (dispatch) => {
    api.delete(`mealPlanTemplateFoodEntry/${id}`).then(() => {
        dispatch({
            type: types.DELETE_FOOD_ENTRY,
            id,
        });
    });
};

export const onUpdateFoodEntry = ({ id, weight, timeOfDayId }) => (dispatch) => {
    const data = {};
    if (weight) {
        data.weight = weight;
    }
    if (timeOfDayId) {
        data.timeOfDayId = timeOfDayId;
    }

    api.put(`mealPlanTemplateFoodEntry/${id}`, data).then(({ data }) => {
        dispatch({
            type: types.UPDATE_FOOD_ENTRY,
            foodEntry: {
                ...data,
            }
        });
    });
};

export const onCopyMealPlanTemplateToMealPlan = ({ mealPlanTemplateId, date }) => (dispatch) => {
    dispatch({
        type: types.UPDATE,
        payload: {
            isCopyingTemplate: true,
        },
    });
    const data = {
        mealPlanTemplateId,
        date,
    };
    return new Promise((resolve) => {
        api.post('mealPlanTemplate/copyToMealPlan', data).then(() => {
            dispatch({
                type: types.UPDATE,
                payload: {
                    isCopyingTemplate: false,
                },
            });
            resolve();
        });
    });
};

export const onCleanupTemplates = () => ({
    type: types.UPDATE,
    payload: {
        list: [],
    },
});

export const onCleanupTemplate = () => ({
    type: types.UPDATE,
    payload: {
        chosen: undefined,
    },
});
