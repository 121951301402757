import { connect } from 'react-redux';
import TimeSlot from '../time-slot/Time-slot';
import * as actions from "./meal-plan-actions";
import {bindActionCreators} from "redux";

function mapStateToProps(state, ownProps) {
    const {
        id,
    } = ownProps;

    const foodEntries = (state.mealPlan.foodEntries||[]).filter(({ time_of_day_id }) => time_of_day_id === id);

    return {
        foodEntries,
    };
}

const mapDispatchToProps = (dispatch) => {
    const {
        onCreateFoodEntries,
        onUpdateFoodEntry,
        onDeleteFoodEntry,
    } = actions;
    return bindActionCreators({
        onCreateFoodEntries,
        onUpdateFoodEntry,
        onDeleteFoodEntry,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeSlot);
