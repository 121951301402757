import * as types from './meal-plan-action-types';
import api from "../../api";
import moment from "moment";

export const onLoad = () => (dispatch, getState) => {
    dispatch({
        type: types.UPDATE,
        payload: {
            isLoading: true,
        }
    });
    const state = getState();
    // Fetch times of day
    api.get('timeOfDay').then(({ data }) => {
        dispatch({
            type: types.UPDATE,
            payload: {
                timeOfDayList: data,
            }
        });
    });

    // Fetch mealplan
    const dateSetTime = state.mealPlan.dateSetTime;
    const now = moment();
    const isSetToday = now.diff(dateSetTime, 'days') === 0;
    const date =  isSetToday ? state.mealPlan.date : now;
    api.get(`foodEntry?date=${date.toDate().toISOString()}`).then(({ data }) => {
        dispatch({
            type: types.UPDATE,
            payload: {
                foodEntries: data,
                isLoading: false,
            }
        });
    });
};

export const onCreateFoodEntries = (foodEntries) => (dispatch, getState) => {
    const state = getState();
    const date = state.mealPlan.date.toDate().toISOString();
    const data = {
        foodEntries: foodEntries.map(({food, weight, food_measure_id, timeOfDayId}) => ({
            date,
            timeOfDayId,
            foodId: food.id,
            weight,
            food_measure_id,
        }))
    };
    return new Promise((resolve) => {
        api.post('foodEntries', data).then((response) => {
            dispatch({
                type: types.ADD_FOOD_ENTRIES,
                foodEntries: response.data,
            });
            resolve();
        });
    });
};

export const onDeleteFoodEntry = ({ id }) => (dispatch) => {
    api.delete(`foodEntry/${id}`).then(() => {
        dispatch({
            type: types.DELETE_FOOD_ENTRY,
            id,
        });
    });
};

export const onUpdateFoodEntry = ({ id, weight, timeOfDayId }) => (dispatch) => {
    const data = {};
    if (weight) {
        data.weight = weight;
    }
    if (timeOfDayId) {
        data.timeOfDayId = timeOfDayId;
    }

    api.put(`foodEntry/${id}`, data).then(({ data }) => {
        dispatch({
            type: types.UPDATE_FOOD_ENTRY,
            foodEntry: {
                ...data,
            }
        });
    });
};

export const onChangeDate = ({ date }) => (dispatch) => {
    dispatch({
        type: types.UPDATE,
        payload: {
            isLoading: true,
        }
    });
    api.get(`foodEntry?date=${date.toDate().toISOString()}`).then(({ data }) => {
        dispatch({
            type: types.UPDATE,
            payload: {
                foodEntries: data,
                date,
                dateSetTime: moment(),
                isLoading: false,
            }
        });
    });
};