import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BodyWeightGraph from './body-weight-graph';
import * as actions from './body-weight-actions';

function mapStateToProps(state) {
    const {
        auth,
        bodyWeight,
    } = state;
    return {
        weights: bodyWeight.weights,
        userId: auth.id,
    };
}


const mapDispatchToProps = (dispatch) => {
    const {
        onLoad,
    } = actions;
    return bindActionCreators({
        onLoad: onLoad,
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(BodyWeightGraph);
