import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BodyMeasures from './body-measures';
import * as actions from './actions';

function mapStateToProps(state) {
    const {
        bodyMeasures,
    } = state;

    const hasData = bodyMeasures.measures && bodyMeasures.measures.length > 0;

    return {
        hasData,
        isLoading: bodyMeasures.isLoading,
    };
}


const mapDispatchToProps = (dispatch) => {
    const {
        onLoad,
    } = actions;
    return bindActionCreators({
        onLoad: onLoad,
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(BodyMeasures);
