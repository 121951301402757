import * as types from './auth-action-types';
import { setCookie } from '../../helpers/cookie';
import api from '../../api';

export const onLogin = ({ username, password }) => (dispatch) => {
    api.post('auth/login', {name: username, password }, false)
        .then(({ data }) => {
        const token = data.token;

        dispatch({
            type: types.SET_AUTH_TOKEN,
            token,
        });
        setCookie('auth_token', token, 30);
    });
};

export const onLogOut = () => (dispatch) => {
    dispatch({
        type: types.REMOVE_AUTH_TOKEN,
    });
    setCookie('auth_token', undefined, -1);
};
