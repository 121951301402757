import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import LoadingSpinner from "../core/loading-spinner";
import {Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { getFoodEntriesTotalNutrition } from '../../engine/nutrition';
import NutritionChart from "../nutrition/Nutrition-chart";

const styles = theme => ({
    root: {
        position: 'relative',
        padding: 16,
        maxWidth: 700,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    paper: {
        padding: '20px',
    },
    link: {
        textDecoration: 'none',
    },
});

class MealPlan extends Component {
    static props = {
        onLoad: PropTypes.func.isRequired,
        onCleanup: PropTypes.func.isRequired,
        templates: PropTypes.arrayOf({
            id: PropTypes.number,
            name: PropTypes.string,
        }),
        isLoading: PropTypes.bool,
        classes: PropTypes.object.isRequired,
    };

    static defaultProps = {
        templates: [],
    };

    componentDidMount() {
        const {
            onLoad,
        } = this.props;
        if (onLoad) {
            onLoad();
        }
    }

    componentWillUnmount() {
        const {
            onCleanup,
        } = this.props;

        if (onCleanup) {
            onCleanup();
        }
    }

    render() {
        const {
            id,
            classes,
            isLoading,
            templates,
        } = this.props;

        return (
            <div key={id} className={classes.root}>
                {isLoading && <LoadingSpinner />}
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper elevation={1} className={classes.paper}>
                            <Typography variant={"h5"}>Kostplan-skabeloner</Typography>
                            <br/>
                            <Link to="/ny-skabelon" className={classes.link}>
                                <Button variant={"outlined"} color="primary" fullWidth >
                                    Ny skabelon
                                </Button>
                            </Link>
                            <br />
                            <br />
                            { templates.length > 0 && (<Fragment>
                                <Typography variant={"caption"}>Eksisterende:</Typography>
                                <List>
                                    {templates.map(({id, name, food_entries}) => {
                                        const {
                                            kcal,
                                            fat,
                                            protein,
                                            carbohydrates,
                                        } = getFoodEntriesTotalNutrition(food_entries);
                                        return (
                                            <Link to={`/skabelon/${id}`} className={classes.link} key={id}>
                                                <ListItem button>
                                                    <ListItemText
                                                        primary={name || "Unavngivet skabelon"}
                                                        primaryTypographyProps={ {variant: 'body1'}}
                                                    />
                                                    <Typography variant={"caption"} className={classes.nowrap}>{Math.round(kcal)} kcal&nbsp;</Typography>
                                                    <NutritionChart
                                                        size={"mini"}
                                                        fat={fat}
                                                        protein={protein}
                                                        carbohydrates={carbohydrates}
                                                    />
                                                </ListItem>
                                            </Link>
                                        );
                                    })}
                                </List>
                            </Fragment>)}
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(MealPlan);
