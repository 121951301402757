import * as types from './action-types';
import moment from "moment";

export default function bodyMeasuresReducer(state = {}, action) {
    switch (action.type) {
        case types.UPDATE:{
            return {
                ...state,
                ...action.payload,
            };
        }
        case types.ADD_MEASURES:{
            const isInState = state.measures.find(({date}) => moment(date).isSame(action.payload.date, 'day'));
            if (isInState) {
                return {
                    ...state,
                    measures: [
                        ...state.measures.map((measure) => {
                            if (moment(measure.date).isSame(action.payload.date, 'day')) {
                                return action.payload;
                            } else {
                                return measure;
                            }
                        })
                    ],
                };
            } else {
                return {
                    ...state,
                    measures: [
                        ...state.measures,
                        action.payload,
                    ],
                };
            }
        }
        default:
            return state;
    }
}
