import React, {Component} from 'react';
import PropTypes from 'prop-types';
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import orange from "@material-ui/core/colors/orange";
import {
    ResponsiveContainer,
    LineChart,
    XAxis,
    YAxis,
    Tooltip,
    Line,
    ReferenceLine,
    CartesianGrid
} from 'recharts';
import moment from "moment";
import ReferenceLineLabel from './graph-custom-elements/referenceLineLabel';
import YAxisTick from './graph-custom-elements/yAxisTick';
import XAxisTick from './graph-custom-elements/xAxisTick';
import MyToolTip from './graph-custom-elements/tooltip';

class BodyWeightGraph extends Component {
    static props = {
        weights: PropTypes.arrayOf({
            date: PropTypes.string.isRequired,
            weight: PropTypes.string.isRequired,
        }),
        userId: PropTypes.number.isRequired,
    };

    static defaultProps = {
        weights: [],
    };

    render() {
        const {
            weights,
            userId,
        } = this.props;

        const sortedData = weights.sort((a,b) => moment(a.date).format('YYYYMMDD') - moment(b.date).format('YYYYMMDD'));

        const yAxisDomain = userId === 1 ? [50, 60] : [dataMin => (Math.floor(dataMin) -1), dataMax => (Math.ceil(dataMax) + 1)];

        return (<ResponsiveContainer width="100%" height={200}>
            <LineChart
                data={sortedData}
                margin={{ top: 20, right: 10, left: -32, bottom: 10 }}
            >
                {userId === 1 && (<ReferenceLine y={59} stroke={"#ccc"} strokeDasharray="2 5" />)}
                {userId === 1 && (<ReferenceLine y={58} stroke={"#ccc"} strokeDasharray="2 5" />)}
                {userId === 1 && (<ReferenceLine y={57} stroke={"#ccc"} strokeDasharray="2 5" />)}
                {userId === 1 && (<ReferenceLine y={56} stroke={"#ccc"} strokeDasharray="2 5" />)}
                {userId === 1 && (<ReferenceLine y={55} stroke={"#ccc"} strokeDasharray="2 5" />)}
                {userId === 1 && (<ReferenceLine y={54} stroke={"#ccc"} strokeDasharray="2 5" />)}
                {userId === 1 && (<ReferenceLine y={53} stroke={"#ccc"} strokeDasharray="2 5" />)}
                {userId === 1 && (<ReferenceLine y={52} stroke={"#ccc"} strokeDasharray="2 5" />)}
                {userId === 1 && (<ReferenceLine y={51} stroke={"#ccc"} strokeDasharray="2 5" />)}
                {userId === 1 && (<ReferenceLine y={50} stroke={"#ccc"} strokeDasharray="2 5" />)}
                {userId === 1 && (<ReferenceLine y={60} stroke={red[500]} label={<ReferenceLineLabel label={"Post Maja"} fill={red[500]} />}/>)}
                {userId === 1 && (<ReferenceLine y={"55.4"} stroke={orange[500]} label={<ReferenceLineLabel label={"Post Rebecca"} fill={orange[500]} />}/>)}
                {userId === 1 && (<ReferenceLine y={"52.5"} stroke={green[500]} label={<ReferenceLineLabel label={"Mål"} fill={green[500]} />}/>)}
                {userId !== 1 && <CartesianGrid strokeDasharray="3 3" />}
                <XAxis minTickGap={1} dataKey="date" tick={<XAxisTick />} />
                <YAxis allowDecimals={false} dataKey="weight" type={"number"} domain={yAxisDomain} tickCount={10} tick={<YAxisTick />} />
                <Tooltip content={<MyToolTip />} />
                <Line isAnimationActive={false} type="monotone" dataKey="weight" stroke="#3e2723" />
            </LineChart>
        </ResponsiveContainer>);
    }
}

export default BodyWeightGraph;
