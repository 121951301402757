import { connect } from 'react-redux';
import AddBodyMeasuresDialog from './add-weight-dialog';

function mapStateToProps(state) {
    const {
        bodyWeight,
    } = state;
    return {
        weights: bodyWeight.weights,
    };
}

export default connect(mapStateToProps)(AddBodyMeasuresDialog);
