import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FoodMeasureCreator from './Food-measure-creator';
import * as actions from "./food-measure-actions";

function mapStateToProps(state, ownProps) {
    const {
        foodId,
    } = ownProps;
    return {
        foodId
    };
}

const mapDispatchToProps = (dispatch) => {
    const {
        onCreateFoodMeasure,
    } = actions;
    return bindActionCreators({
        onCreateFoodMeasure: onCreateFoodMeasure,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(FoodMeasureCreator);
