import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Typography,
    withStyles,
    Grid,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Divider,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NutritionChart from '../nutrition/Nutrition-chart';
import FoodEntryCreator from '../food-entry/Food-entry-creator';
import NutritionTable from '../nutrition/nutrition-table';

const styles = theme => ({
    expansionPanelDetails: {
        display: 'block',
    },
});

class FoodListItem extends Component {
    static props = {
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        kcal: PropTypes.string.isRequired,
        fat: PropTypes.string.isRequired,
        protein: PropTypes.string.isRequired,
        carbohydrates: PropTypes.string.isRequired,
        measures: PropTypes.arrayOf({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            weight: PropTypes.number.isRequired,
        }),
        onCreateFoodEntries: PropTypes.func,
        classes: PropTypes.object.isRequired,
    };

    static defaultProps = {
        measures: [],
    };

    state = {
        isExpanded: false,
    };

    handleToggleIsExpanded = () => {
        this.setState((prevState) => {
            return {
                isExpanded: !prevState.isExpanded,
            };
        });
    };

    handleCreateFoodEntries = (foodEntries) => {
        this.setState({isExpanded: false});
        const {
            onCreateFoodEntries,
            id,
            name,
            kcal,
            fat,
            protein,
            carbohydrates,
            measures,
        } = this.props;
        onCreateFoodEntries(foodEntries.map((foodEntry) => ({
            ...foodEntry,
            food: {
                id,
                name,
                kcal,
                fat,
                protein,
                carbohydrates,
                measures,
            },
        })));
    };

    render() {
        const {
            id,
            name,
            kcal,
            fat,
            protein,
            carbohydrates,
            measures,
            classes,
        } = this.props;

        return (
            <ExpansionPanel expanded={this.state.isExpanded} onChange={this.handleToggleIsExpanded}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    {name}
                    {parseInt(kcal) !== 0 && (<React.Fragment>
                        &nbsp;&nbsp;
                        <NutritionChart
                            size={"mini"}
                            fat={fat}
                            protein={protein}
                            carbohydrates={carbohydrates}
                        />
                    </React.Fragment>)}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                    <FoodEntryCreator
                        onCreateFoodEntries={this.handleCreateFoodEntries}
                        foodId={id}
                        measures={measures}
                    />
                    <Divider />
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <NutritionTable
                                carbohydrates={carbohydrates}
                                fat={fat}
                                kcal={kcal}
                                protein={protein}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {parseInt(kcal) !== 0 && (<React.Fragment>
                                <Typography variant={"caption"}>
                                    Energifordeling:
                                </Typography>
                                <NutritionChart
                                    fat={fat}
                                    protein={protein}
                                    carbohydrates={carbohydrates}
                                />
                            </React.Fragment>)}
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

export default withStyles(styles)(FoodListItem);
