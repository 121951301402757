import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AddBodyMeasuresDialog from './add-body-measures-dialog';
import * as actions from './actions';

function mapStateToProps(state) {
    const {
        bodyMeasures,
    } = state;
    return {
        measures: bodyMeasures.measures,
    };
}


const mapDispatchToProps = (dispatch) => {
    const {
        onAddMeasures,
    } = actions;
    return bindActionCreators({
        onAddMeasures: onAddMeasures,
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(AddBodyMeasuresDialog);
