import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CopyMealToMealPlan from './copy-meal-to-meal-plan';
import * as actions from './actions';

function mapStateToProps(state) {
    const {
        meal,
    } = state;

    return {
        isCopyingMeal: meal.isCopyingMeal,
    }
}


const mapDispatchToProps = (dispatch) => {
    const {
        onCopyMealToMealPlan,
    } = actions;
    return bindActionCreators({
        onCopyMealToMealPlan,
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(CopyMealToMealPlan);
