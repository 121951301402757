import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import '../time-slot/time-slot.css';
import CreateExerciseEntryDialog from './Create-exercise-entry-dialog-connected';
import ExerciseEntry from "./Exercise-entry";
import LoadingSpinner from "../core/loading-spinner";

const styles = {
};

class Exercise extends Component {
    static props = {
        exerciseEntries: PropTypes.arrayOf({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            kcal: PropTypes.number.isRequired,
        }),
        onLoad: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        classes: PropTypes.object.isRequired,
    };

    static defaultProps = {
        exerciseEntries: [],
    };

    state = {
        showAddExerciseEntry: false,
    };

    componentDidMount() {
        this.props.onLoad();
    }

    handleToggleShowAddExerciseEntry = () => {
        this.setState((prevState) => {
            return {
                showAddExerciseEntry: !prevState.showAddExerciseEntry,
            };
        });
    };

    render() {
        const {
            isLoading,
            exerciseEntries,
        } = this.props;

        const kcalArray = exerciseEntries.map((entry) => entry.kcal);
        const kcal = kcalArray.length ? kcalArray.reduce((total, number) => parseFloat(total) + parseFloat(number)) : 0;

        return (<div className="timeslot">
            {isLoading && <LoadingSpinner />}
            <Table padding="checkbox">
                <TableHead>
                    <TableRow className="timeslot-table-head-row" onClick={this.handleToggleShowAddExerciseEntry}>
                        <TableCell>
                            Motion
                        </TableCell>
                        <TableCell align="right">
                            {Math.round(kcal)} kcal
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {exerciseEntries.map((entry) => (
                        <ExerciseEntry key={entry.id} id={entry.id} name={entry.name} kcal={entry.kcal} />
                    ))}
                </TableBody>
            </Table>
            <CreateExerciseEntryDialog
                open={this.state.showAddExerciseEntry}
                onClose={this.handleToggleShowAddExerciseEntry}
            />
        </div>);
    }
}

export default withStyles(styles)(Exercise);
