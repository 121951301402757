import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {CircularProgress, Typography, withStyles} from "@material-ui/core";
import BodyMeasuresGraph from './body-measures-graph-connected';
import BodyMeasuresText from './body-measures-text-connected';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import AddBodyMeasuresDialog from './add-body-measures-dialog-connected';

const styles = theme => ({
    root: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    addButton: {
        marginTop: -7,
    },
});

class BodyMeasures extends Component {
    static props = {
        isLoading: PropTypes.bool.isRequired,
        hasData: PropTypes.bool.isRequired,
        onLoad: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired,
    };

    componentDidMount() {
        this.props.onLoad();
    }

    state = {
        showAddMeasures: false,
    };

    handleToggleAddMeasures = () => {
        this.setState((prevState) => {
            return {
                showAddMeasures: !prevState.showAddMeasures,
            };
        });
    };

    render() {
        const {
            classes,
            isLoading,
            hasData,
        } = this.props;
        const {
            showAddMeasures,
        } = this.state;

        if (isLoading) {
            return (<div className={classes.progressContainer}>
                <CircularProgress color={"secondary"} size={20} />
            </div>);
        }

        return (<div className={classes.root}>
            <div className={classes.flexContainer}>
                <Typography variant={"subtitle1"}>Kropskomposition</Typography>
                <Fab size={"small"} color={'primary'} onClick={this.handleToggleAddMeasures} className={classes.addButton}>
                    <AddIcon />
                </Fab>
            </div>
            {!hasData && (
                <Fragment>
                    <br/>
                    <Typography variant={"body1"}>Indtast din fedprocent, vandprocent, og/eller muskelmasse, hvis du har lyst til at se en graf her.</Typography>
                </Fragment>
            )}
            {hasData && <BodyMeasuresGraph />}
            {hasData && <BodyMeasuresText />}
            <AddBodyMeasuresDialog
                open={showAddMeasures}
                onClose={this.handleToggleAddMeasures}
            />
        </div>);
    }
}

export default withStyles(styles)(BodyMeasures);
