import * as types from './body-weight-action-types';
import api from '../../api';

export const onLoad = () => (dispatch) => {
    dispatch({
        type: types.UPDATE,
        payload: {
            isLoading: true,
        },
    });
    api.get('bodyMeasure').then(({ data }) => {
        const weights = data.filter(({weight}) => weight);
        dispatch({
            type: types.UPDATE,
            payload: {
                isLoading: false,
                weights: weights.map(({date, weight})=> {
                    return {
                        date,
                        weight : parseFloat(weight),
                    }
                })
            },
        });
    });
};

export const onAddWeightMeasure = ({ date, weight }) => (dispatch) => {
    dispatch({
        type: types.UPDATE,
        payload: {
            isLoading: true,
        },
    });
    const data = {
        date,
        weight,
    };
    api.post('bodyMeasure', data).then(({ data }) => {
        dispatch({
            type: types.ADD_WEIGHT_MEASURE,
            payload: {
                date: data.date,
                weight: parseFloat(data.weight),
            },
        });
        dispatch({
            type: types.UPDATE,
            payload: {
                isLoading: false,
            },
        });
    });
};
