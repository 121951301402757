import {composeWithDevTools} from "redux-devtools-extension";
import {applyMiddleware, combineReducers, createStore} from "redux";
import thunkMiddleware from "redux-thunk";
import authReducer from "./components/auth/auth-reducer";
import foodReducer from "./components/food/food-reducer";
import mealPlanReducer from "./components/meal-plan/meal-plan-reducer";
import bodyWeightReducer from "./components/body-weight/body-weight-reducer";
import bodyMeasuresReducer from "./components/body-measures/reducer";
import exerciseReducer from "./components/exercise/reducer";
import mealReducer from "./components/meal/reducer";
import mealPlanTemplateReducer from "./components/meal-plan-template/reducer";
import timeOfDayReducer from "./components/time-of-day/reducer";
import {getCookie} from "./helpers/cookie";
import moment from "moment";
import jwtDecode from "jwt-decode";

const composeEnhancers = composeWithDevTools({});
const enhancer = composeEnhancers(applyMiddleware(thunkMiddleware));
const reducer = combineReducers({
    auth: authReducer,
    food: foodReducer,
    mealPlan: mealPlanReducer,
    mealPlanTemplate: mealPlanTemplateReducer,
    bodyWeight: bodyWeightReducer,
    bodyMeasures: bodyMeasuresReducer,
    exercise: exerciseReducer,
    meal: mealReducer,
    timeOfDay: timeOfDayReducer,
});

const authToken = getCookie('auth_token');

const initialStore = {
    auth: {
        token: authToken,
        id: authToken ? jwtDecode(authToken).sub : undefined,
    },
    food: {
        isLoading: false,
        list: [],
    },
    mealPlan: {
        timeOfDayList: [],
        date: moment(),
        dateSetTime: moment(),
    },
    mealPlanTemplate: {
        list: [],
        chosen: {
            food_entries: [],
        },
        isLoading: false,
        isCopyingTemplate: false,
    },
    meal: {
        list: [],
        chosen: undefined,
        isLoading: false,
        isCopyingMeal: false,
        isCopyingMealToTemplate: false,
    },
    bodyWeight: {
        isLoading: false,
        weights: [],
    },
    bodyMeasures: {
        isLoading: false,
        measures: [],
    },
    exercise: {
        isLoading: false,
        entries: [],
    },
    timeOfDay: {
        list: [],
        isLoading: false,
    },
};
const store = createStore(reducer, initialStore, enhancer);
export default store;