import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Typography, TextField, Button, InputAdornment, withStyles, Paper} from "@material-ui/core";
/* eslint-disable react/jsx-no-duplicate-props */

const styles = theme => ({
    flexGrid: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    padding: {
        padding: 20,
    }
});

class FoodCreator extends Component {
    static props = {
        onCreateFood: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired,
    };

    onSubmit = (event) => {
        event.preventDefault();

        const {
            onCreateFood,
        } = this.props;

        const {
            foodname: nameInput,
            kcal: kcalInput,
            fat: fatInput,
            protein: proteinInput,
            carbohydrates: carbohydratesInput,
        } = event.target;

        onCreateFood({
            name: nameInput.value,
            kcal: parseFloat(kcalInput.value.replace(',', '.')),
            fat: parseFloat(fatInput.value.replace(',', '.')),
            protein: parseFloat(proteinInput.value.replace(',', '.')),
            carbohydrates: parseFloat(carbohydratesInput.value.replace(',', '.')),
        });
    };

    handleCancel = (event) => {
        event.preventDefault();
        this.props.onCancel();
    };

    render() {
        const {
            classes,
        } = this.props;

        return (
            <Paper className={classes.padding}>
                <Typography variant={"button"} >
                    Opret ny madvare
                </Typography>
                <form onSubmit={this.onSubmit}>
                    <TextField
                        label={"Navn"}
                        type="text"
                        name="foodname"
                        required
                        fullWidth
                    />
                    <br/>
                    <br/>
                    <br/>
                    <Typography variant={"subtitle2"}>Næringsindhold per 100g:</Typography>
                    <TextField
                        label={"Energi"}
                        type="number"
                        name="kcal"
                        required
                        fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position="end">kcal</InputAdornment>,
                        }}
                        inputProps={{
                            step: "any",
                            min: 0,
                        }}
                    />
                    <br/>
                    <TextField
                        label={"Fedt"}
                        type="number"
                        name="fat"
                        required
                        fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position="end">g</InputAdornment>,
                        }}
                        inputProps={{
                            step: "any",
                            min: 0,
                        }}
                    />
                    <br/>
                    <TextField
                        label={"Kulhydrat"}
                        type="number"
                        name="carbohydrates"
                        required
                        fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position="end">g</InputAdornment>,
                        }}
                        inputProps={{
                            step: "any",
                            min: 0,
                        }}
                    />
                    <br/>
                    <TextField
                        label={"Protein"}
                        type="number"
                        name="protein"
                        required
                        fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position="end">g</InputAdornment>,
                        }}
                        inputProps={{
                            step: "any",
                            min: 0,
                        }}
                    />
                    <br/>
                    <br/>
                    <div className={classes.flexGrid}>
                        <Button
                            variant={"outlined"}
                            onClick={this.handleCancel}
                        >
                            Fortryd
                        </Button>
                        <Button
                            type="submit"
                            color={"primary"}
                            variant={"contained"}
                        >
                            Opret madvare
                        </Button>
                    </div>
                </form>
            </Paper>
        );
    }
}

export default withStyles(styles)(FoodCreator);
