import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Table, TableRow, TableCell, TableHead, TableBody, Dialog, Typography} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import CloseIcon from '@material-ui/icons/Close';
import FoodIcon from '@material-ui/icons/Restaurant';
import FoodEntry from '../food-entry/Food-entry';
import FoodList from '../food/Food-list-connected';
import './time-slot.css';
import {
    getFoodEntriesTotalNutrition,
} from '../../engine/nutrition';
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import green from "@material-ui/core/es/colors/green";
import teal from "@material-ui/core/es/colors/teal";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import NutritionChart from "../nutrition/Nutrition-chart";

const styles = {
    dialog: {
       maxWidth: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    dialogHeader: {
        padding: '15px 20px 10px',
        position: 'sticky',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: teal[600],
        textTransform: 'uppercase',
        zIndex: 2,
    },
    dialogHeaderText: {
        color: 'white',
        marginTop: -3,
    },
    dialogContent: {
        padding: '0 20px 20px',
    },
    closeButton: {
        position: 'absolute',
        top: 3,
        right: 4,
        color: 'white',
    },
    snackbarContent: {
        backgroundColor: green[600],
    },
    snackbarIcon: {
        fontSize: 20,
    },
    foodIcon: {
        paddingTop: 6,
    }
};

class TimeSlot extends Component {
    static props = {
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        timeOfDayList: PropTypes.arrayOf({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        }),
        foodEntries: PropTypes.arrayOf({
            id: PropTypes.number.isRequired,
            weight: PropTypes.number.isRequired,
            food_measure_id: PropTypes.number.isRequired,
            food: PropTypes.shape({
                kcal: PropTypes.string.isRequired,
            }).isRequired,
        }),
        onCreateFoodEntries: PropTypes.func.isRequired,
        onDeleteFoodEntry: PropTypes.func.isRequired,
        onUpdateFoodEntry: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired,
    };

    static defaultProps = {
        foodEntries: [],
    };

    state = {
        showAddFood: false,
        showSnackbar: false,
    };

    handleToggleShowAddFood = () => {
        this.setState((prevState) => {
            return {
                showAddFood: !prevState.showAddFood,
            };
        });
    };

    handleCreateFoodEntries = (foodEntries) => {
        const {
            id,
            onCreateFoodEntries,
        } = this.props;

        onCreateFoodEntries(foodEntries.map((foodEntry) => ({
            ...foodEntry,
            timeOfDayId: id
        }))).then(() => {
            this.setState({
                showSnackbar: true,
            });
        })
    };

    handleHideSnackbar = () => {
        this.setState({
            showSnackbar: false,
        });
    };

    render() {
        const {
            name,
            foodEntries,
            classes,
            id,
            timeOfDayList,
            onDeleteFoodEntry,
            onUpdateFoodEntry,
        } = this.props;

        const {
            showSnackbar,
            showAddFood,
        } = this.state;

        const {
            kcal,
            fat,
            protein,
            carbohydrates,
        } = getFoodEntriesTotalNutrition(foodEntries);

        return (<div className="timeslot">
            <Table padding="checkbox">
                <TableHead>
                    <TableRow className="timeslot-table-head-row" onClick={this.handleToggleShowAddFood}>
                        <TableCell colSpan={2}>
                            {name}&nbsp;&nbsp;
                            <NutritionChart
                                fat={fat}
                                protein={protein}
                                carbohydrates={carbohydrates}
                                size={"mini"}
                            />
                        </TableCell>
                        <TableCell align="right">
                            {Math.round(kcal)} kcal
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {foodEntries.map(({ id, weight, food, food_measure_id, time_of_day_id }) => (
                        <FoodEntry
                            key={id}
                            id={id}
                            weight={weight}
                            food={food}
                            food_measure_id={food_measure_id}
                            time_of_day_id={time_of_day_id}
                            timeOfDayList={timeOfDayList}
                            onDeleteFoodEntry={onDeleteFoodEntry}
                            onUpdateFoodEntry={onUpdateFoodEntry}
                        />
                    ))}
                </TableBody>
            </Table>
            <Dialog open={showAddFood} onClose={this.handleToggleShowAddFood} fullScreen className={classes.dialog}>
                <div className={classes.dialogHeader}>
                    <IconButton aria-label="Close" className={classes.closeButton} onClick={this.handleToggleShowAddFood}>
                        <CloseIcon />
                    </IconButton>
                    <Typography variant={"h5"} className={classes.dialogHeaderText}>
                        <FoodIcon fontSize={"inherit"} className={classes.foodIcon} />&nbsp;&nbsp;{name}
                    </Typography>
                </div>
                <div className={classes.dialogContent}>
                    <br />
                    <FoodList timeOfDayId={id} onCreateFoodEntries={this.handleCreateFoodEntries} />
                </div>
            </Dialog>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                variant={"success"}
                open={showSnackbar}
                autoHideDuration={6000}
                onClose={this.handleHideSnackbar}
            >
                <SnackbarContent className={classes.snackbarContent}
                     message={"Madvare tilføjet"}
                     action={[
                         <IconButton
                             key="Luk"
                             aria-label="Luk"
                             color="inherit"
                             onClick={this.handleHideSnackbar}
                         >
                             <CloseIcon className={classes.snackbarIcon} />
                         </IconButton>,
                     ]}
                />
            </Snackbar>
        </div>);
    }
}

export default withStyles(styles)(TimeSlot);
