import * as types from './action-types';

export default function timeOfDayReducer(state = {}, action) {
    switch (action.type) {
        case types.UPDATE:{
            return {
                ...state,
                ...action.payload,
            };
        }
        default:
            return state;
    }
}
