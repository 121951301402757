import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell, Legend } from 'recharts';
import './nutrition-chart.css';
import Typography from "@material-ui/core/Typography";

const convertToChartValue = (value) => {
    // Hvis vi har 0,xx, så vil vi gerne have det rundet op, for 0 tegnes ikke.
    return Math.round(value) === 0 ? Math.ceil(value) : Math.round(value);
};

const isZero = (value) => {
    return value === "0.00" || value === 0;
};

class NutritionChart extends Component {
    static props = {
        fat: PropTypes.string.isRequired,
        protein: PropTypes.string.isRequired,
        carbohydrates: PropTypes.string.isRequired,
        size: PropTypes.oneOf(['default', 'mini']),
    };

    static defaultProps = {
        size: 'default',
    };

    render() {
        const {
            fat,
            protein,
            carbohydrates,
            size,
        } = this.props;

        if (isZero(fat) && isZero(protein) && isZero(carbohydrates)) {
            // Tegn ikke noget, hvis der ikke er noget, fx for vand
            return null;
        }

        // Værdier for hvor meget 1g svarer til i kJ ganges på
        const data = [
            { name: 'Fedt', value: convertToChartValue(fat*37) },
            { name: 'Kulhydrat', value: convertToChartValue(carbohydrates*17) },
            { name: 'Protein', value: convertToChartValue(protein*17) },
        ];

        const COLORS = ['#E3971E', '#7cb342', '#00acc1'];

        const renderLegend = (props) => {
            const { payload } = props;

            return (
                <ul className="nutrition-chart-legend">
                    {
                        payload.map((entry, index) => (
                            <li
                                className="nutrition-chart-legend-item"
                                key={`item-${index}`}
                            >
                                <Typography variant="caption" style={{color: COLORS[index % COLORS.length]}}>
                                    {entry.value}: {`${(entry.payload.percent * 100).toFixed(1).replace('.',',')}%`}
                                </Typography>
                            </li>
                        ))
                    }
                </ul>
            );
        }

        const width = size === 'mini' ? 16 : 140;
        const height = size === 'mini' ? 16 : 60;
        const cx = size === 'mini' ? 3 : 15;
        const cy = size === 'mini' ? 3 : 24;
        const outerRadius = size === 'mini' ? 8 : 20;

        return (
            <PieChart width={width} height={height}>
                <Pie
                    data={data}
                    cx={cx}
                    cy={cy}
                    labelLine={false}
                    outerRadius={outerRadius}
                    fill="#8884d8"
                    dataKey="value"
                    isAnimationActive={false}
                >
                    {
                        data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                    }
                </Pie>
                {size !== 'mini' && (
                    <Legend
                        verticalAlign="middle"
                        align="right"
                        layout="vertical"
                        content={renderLegend}
                    />
                )}
            </PieChart>
        );
    }
}

export default NutritionChart;
