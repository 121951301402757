import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import {InputAdornment, TextField, Typography} from "@material-ui/core";
import TimeOfDayPicker from "../time-of-day/time-of-day-picker-connected";
import { DatePicker } from '@material-ui/pickers';
import moment from "moment";
import Button from "@material-ui/core/Button";
import LoadingSpinner from "../core/loading-spinner";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import green from "@material-ui/core/es/colors/green";
/* eslint-disable react/jsx-no-duplicate-props */

const styles = theme => ({
    root: {
        position: 'relative',
    },
    card: {
        padding: '10px 20px',
    },
    datepicker: {
        width: '100%',
    },
    flexGrid: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    snackbarContent: {
        backgroundColor: green[600],
    },
    snackbarIcon: {
        fontSize: 20,
    },
});

class CopyMealToMealPlan extends Component {
    static props = {
        mealId: PropTypes.number.isRequired,
        onCopyMealToMealPlan: PropTypes.func.isRequired,
        isCopyingMeal: PropTypes.bool,
        classes: PropTypes.object.isRequired,
    };

    state = {
        selectedTimeOfDayId: 1,
        date: moment(),
        showSnackbar: false,
    };

    handleUpdateSelectedTimeOfDay = (evt) => {
        this.setState({
            selectedTimeOfDayId: evt.target.value,
        });
    };

    handleDateChange = (date) => {
        this.setState({
            date,
        });
    };

    handleCopyMeal = (evt) => {
        evt.preventDefault();
        const {
            mealId,
            onCopyMealToMealPlan,
        } = this.props;
        const {
            amount: amountInput,
        } = evt.target;
        const amount = parseInt(amountInput.value);
        const {
            selectedTimeOfDayId,
            date,
        } = this.state;
        onCopyMealToMealPlan({mealId, date, timeOfDayId: selectedTimeOfDayId, amount}).then(()=> {
            this.setState({
                showSnackbar: true,
            });
        });
    };

    handleHideSnackbar = () => {
        this.setState({
            showSnackbar: false,
        });
    };

    render() {
        const {
            isCopyingMeal,
            classes,
        } = this.props;

        const {
            selectedTimeOfDayId,
            date,
            showSnackbar,
        } = this.state;

        return (
            <Grid item xs={12} className={classes.root}>
                {isCopyingMeal && <LoadingSpinner message={"Kopierer måltid til kostplan"} />}
                <Card className={classes.card}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant={"subtitle1"}>Kopier måltid til kostplan</Typography>
                            <DatePicker
                                label={"Til dato"}
                                margin="normal"
                                value={date}
                                format={'LL'}
                                showTodayButton
                                onChange={this.handleDateChange}
                                className={classes.datepicker}
                            />
                            <TimeOfDayPicker
                                label={"Til tidspunkt"}
                                selectedTimeOfDayId={selectedTimeOfDayId}
                                onChange={this.handleUpdateSelectedTimeOfDay}
                            />
                            <br />
                            <br />
                            <form onSubmit={this.handleCopyMeal}>
                                <TextField
                                    label={"Mængde"}
                                    type="number"
                                    name="amount"
                                    required
                                    fullWidth
                                    defaultValue={100}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    inputProps={{
                                        min: 1,
                                        max: 100,
                                    }}
                                />
                                <br/>
                                <br/>
                                <div className={classes.flexGrid}>
                                    <Button variant={"contained"} color={"primary"} type={"submit"}>
                                        Kopier
                                    </Button>
                                </div>
                            </form>
                        </Grid>
                    </Grid>
                </Card>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    variant={"success"}
                    open={showSnackbar}
                    autoHideDuration={6000}
                    onClose={this.handleHideSnackbar}
                >
                    <SnackbarContent className={classes.snackbarContent}
                         message={"Måltid kopieret"}
                         action={[
                             <IconButton
                                 key="Luk"
                                 aria-label="Luk"
                                 color="inherit"
                                 onClick={this.handleHideSnackbar}
                             >
                                 <CloseIcon className={classes.snackbarIcon} />
                             </IconButton>,
                         ]}
                    />
                </Snackbar>
            </Grid>
        );
    }
}

export default withStyles(styles)(CopyMealToMealPlan);
