import * as types from './action-types';

export default function mealPlanTemplateReducer(state = {}, action) {
    switch (action.type) {
        case types.UPDATE:{
            return {
                ...state,
                ...action.payload,
            };
        }
        case types.ADD_FOOD_ENTRIES:{
            return {
                ...state,
                chosen: {
                    ...state.chosen,
                    food_entries: [...state.chosen.food_entries, ...action.foodEntries],
                },
            };
        }
        case types.UPDATE_TEMPLATE_NAME:{
            const {
                id,
                name,
            } = action;
            return {
                ...state,
                chosen: {
                    ...state.chosen,
                    name,
                },
                list: state.list.map((listItem) => {
                    if (listItem.id === id) {
                        return {
                            ...listItem,
                            name,
                        }
                    } else {
                        return listItem;
                    }
                })
            };
        }
        case types.DELETE_FOOD_ENTRY:{
            return {
                ...state,
                chosen: {
                    ...state.chosen,
                    food_entries: state.chosen.food_entries.filter(({id}) => id !== action.id),
                },
            };
        }
        case types.UPDATE_FOOD_ENTRY:{
            return {
                ...state,
                chosen: {
                    ...state.chosen,
                    food_entries: state.chosen.food_entries.map((foodEntry) => {
                        if (foodEntry.id !== action.foodEntry.id) {
                            return foodEntry;
                        }
                        return {
                            ...foodEntry,
                            ...action.foodEntry,
                        }
                    }),
                },
            };
        }
        default:
            return state;
    }
}
