import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoginForm from './components/auth/Login-form-connected';
import LogOutButton from './components/auth/Log-out-button-connected';
import MealPlan from './components/meal-plan/Meal-plan-connected';
import MealPlanTemplates from './components/meal-plan-template/Meal-plan-templates-connected';
import MealPlanTemplate from './components/meal-plan-template/Meal-plan-template-connected';
import CreateMealPlanTemplate from './components/meal-plan-template/Create-meal-plan-template';
import Meals from './components/meal/Meals-connected';
import Meal from './components/meal/Meal-connected';
import CreateMeal from './components/meal/Create-meal';
import AppMenu from './components/app-menu/App-menu';
import Grafer from './views/Grafer';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { HashRouter as Router, Route, Link, Routes } from "react-router-dom";
import './App.css';
import { textColor } from "./colors";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';

const styles = {
    appBarContainer: {
        flexGrow: 1,
    },
    root: {
        color: textColor,
    },
    logo: {
        color: 'white',
        flexGrow: 1,
        fontFamily: 'Dokdo'
    },
    logoLink: {
        textDecoration: 'none',
        color: 'inherit',
    },
    container: {
        width: '100%',
        maxWidth: 700,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
};

class App extends Component {
    static props = {
        isLoggedIn: PropTypes.bool,
        onLogOut: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired,
    };

    state = {
        isAppMenuOpen : false,
    };

    handleToggleAppMenu = () => {
        this.setState((prevState) => {
            return {
                isAppMenuOpen: !prevState.isAppMenuOpen,
            };
        });
    };

  render() {
      const {
          isLoggedIn,
          classes,
      } = this.props;

    return (
        <Router>
          <div className={classes.root}>
              <CssBaseline />
              <AppMenu onClose={this.handleToggleAppMenu} isOpen={this.state.isAppMenuOpen} />
              <div className={classes.appBarContainer}>
                  <AppBar position="static">
                      <div className={classes.container}>
                          <Toolbar variant={"dense"}>
                              {isLoggedIn && (
                                  <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={this.handleToggleAppMenu}>
                                      <MenuIcon />
                                  </IconButton>
                              )}
                              <Typography variant={'h4'} className={classes.logo}>
                                <Link to="/" className={classes.logoLink}>
                                  yafL
                                </Link>
                              </Typography>
                              { isLoggedIn && <LogOutButton />}
                          </Toolbar>
                      </div>
                  </AppBar>
              </div>
                {!isLoggedIn && <LoginForm />}
                { isLoggedIn && (<Routes>
                    <Route path="/" exact element={<MealPlan />} />
                    <Route path="/skabeloner" exact element={<MealPlanTemplates />} />
                    <Route path="/ny-skabelon" exact element={<CreateMealPlanTemplate />} />
                    <Route path="/skabelon/:id" element={<MealPlanTemplate />} />
                    <Route path="/grafer" exact element={<Grafer />} />
                    <Route path="/maaltid" exact element={<Meals />} />
                    <Route path="/maaltid/:id" element={<Meal />} />
                    <Route path="/nyt-maaltid" exact element={<CreateMeal />} />
                </Routes>)}
              <div id="apiErrorDialogMounter" />
          </div>
        </Router>
    );
  }
}

export default withStyles(styles)(App);
