import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";

const styles = {
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
};

class AppMenuLink extends Component {
    static props = {
        classes: PropTypes.object.isRequired,
        to: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        icon: PropTypes.node.isRequired,
    };

    render() {
        const {
            to,
            classes,
            icon,
            text,
        } = this.props;

        return (
            <Link to={to} className={classes.link}>
                <ListItem button>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={text} />
                </ListItem>
            </Link>
        );
    }
}

export default withStyles(styles)(AppMenuLink);
