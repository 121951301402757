import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Typography,
    withStyles,
    Table,
    TableBody,
    TableRow,
    TableCell,
} from '@material-ui/core';

const styles = theme => ({
    nutritionTableRow: {
       height: 'auto',
    },
    nutritionTableCell: {
       borderBottom: 0,
        paddingTop: '2px',
        fontSize: '0.75rem',
    },
    nutritionTableContainer: {
        maxWidth: 150,
    }
});

class NutritionTable extends Component {
    static props = {
        kcal: PropTypes.string.isRequired,
        fat: PropTypes.string.isRequired,
        protein: PropTypes.string.isRequired,
        carbohydrates: PropTypes.string.isRequired,
        classes: PropTypes.object.isRequired,
        showHeading: PropTypes.bool,
        heading: PropTypes.string,
    };

    static defaultProps = {
        showHeading: true,
        heading: 'Næring per 100g:',
        fractionDigits: 1,
    };

    formatNumber = (number) => {
        const {
            fractionDigits,
        } = this.props;

        return parseFloat(number).toFixed(fractionDigits).toString().replace(".",",");
    };

    render() {
        const {
            kcal,
            fat,
            protein,
            carbohydrates,
            classes,
            heading,
            showHeading,
        } = this.props;

        return (
            <div className={classes.nutritionTableContainer}>
                {showHeading && <Typography variant={"caption"}>{heading}</Typography>}
                <Table padding={"none"}>
                    <TableBody>
                        <TableRow className={classes.nutritionTableRow}>
                            <TableCell className={classes.nutritionTableCell}>Energi</TableCell>
                            <TableCell className={classes.nutritionTableCell} align={"right"}>{this.formatNumber(kcal).split(',')[0]}</TableCell>
                            <TableCell className={classes.nutritionTableCell}>&nbsp;kcal</TableCell>
                        </TableRow>
                        <TableRow className={classes.nutritionTableRow}>
                            <TableCell className={classes.nutritionTableCell}>Fedt</TableCell>
                            <TableCell className={classes.nutritionTableCell} align={"right"}>{this.formatNumber(fat)}</TableCell>
                            <TableCell className={classes.nutritionTableCell}>&nbsp;g</TableCell>
                        </TableRow>
                        <TableRow className={classes.nutritionTableRow}>
                            <TableCell className={classes.nutritionTableCell}>Kulhydrater</TableCell>
                            <TableCell className={classes.nutritionTableCell} align={"right"}>{this.formatNumber(carbohydrates)}</TableCell>
                            <TableCell className={classes.nutritionTableCell}>&nbsp;g</TableCell>
                        </TableRow>
                        <TableRow className={classes.nutritionTableRow}>
                            <TableCell className={classes.nutritionTableCell}>Protein</TableCell>
                            <TableCell className={classes.nutritionTableCell} align={"right"}>{this.formatNumber(protein)}</TableCell>
                            <TableCell className={classes.nutritionTableCell}>&nbsp;g</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
        );
    }
}

export default withStyles(styles)(NutritionTable);
