import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BodyWeight from './body-weight';
import * as actions from './body-weight-actions';

function mapStateToProps(state) {
    const {
        bodyWeight,
    } = state;
    const hasData = bodyWeight.weights && bodyWeight.weights.length > 0;

    return {
        hasData,
        isLoading: bodyWeight.isLoading,
    };
}


const mapDispatchToProps = (dispatch) => {
    const {
        onLoad,
        onAddWeightMeasure,
    } = actions;
    return bindActionCreators({
        onLoad: onLoad,
        onAddWeightMeasure: onAddWeightMeasure,
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(BodyWeight);
