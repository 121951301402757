import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './auth-actions';
import LogOutButton from './Log-out-button';

function mapStateToProps() {
    return {};
}

const mapDispatchToProps = (dispatch) => {
    const {
        onLogOut,
    } = actions;
    return bindActionCreators({
        onLogOut,
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(LogOutButton);
