import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    TableCell, TableRow
} from '@material-ui/core';
import '../time-slot/time-slot.css';
import UpdateExerciseEntryDialog from "./Update-exercise-entry-dialog-connected";

class ExerciseEntry extends Component {
    static props = {
        name: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        kcal: PropTypes.number.isRequired,
        classes: PropTypes.object.isRequired,
    };

    state = {
        showUpdateExerciseEntry: false,
    };

    handleToggleShowUpdateExerciseEntry = () => {
        this.setState((prevState) => {
            return {
                showUpdateExerciseEntry: !prevState.showUpdateExerciseEntry,
            };
        });
    };

    render() {
        const {
            id,
            name,
            kcal,
        } = this.props;

        const {
            showUpdateExerciseEntry,
        } = this.state;

        return (<Fragment>
            <TableRow className="food-entry-row" onClick={this.handleToggleShowUpdateExerciseEntry}>
                <TableCell>
                    {name}
                </TableCell>
                <TableCell align="right" className="table-cell-kcal">
                    {Math.round(kcal)} kcal
                </TableCell>
            </TableRow>
            <UpdateExerciseEntryDialog
                name={name}
                id={id}
                kcal={kcal}
                open={showUpdateExerciseEntry}
                onClose={this.handleToggleShowUpdateExerciseEntry}
            />
        </Fragment>);
    }
}

export default ExerciseEntry;
