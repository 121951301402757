import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, LineChart, XAxis, YAxis, Tooltip, Line, CartesianGrid } from 'recharts';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import moment from "moment";
import YAxisTick from './graph-custom-elements/yAxisTick';
import XAxisTick from './graph-custom-elements/xAxisTick';
import MyToolTip from './graph-custom-elements/tooltip';

class BodyMeasuresGraph extends Component {
    static props = {
        measures: PropTypes.arrayOf({
            date: PropTypes.string.isRequired,
            fatpercentage: PropTypes.string.isRequired,
            waterpercentage: PropTypes.string.isRequired,
            musclemass: PropTypes.string.isRequired,
        }),
    };

    static defaultProps = {
        measures: [],
    };

    render() {
        const {
            measures,
        } = this.props;

        const sortedData = measures.sort((a,b) => moment(a.date).format('YYYYMMDD') - moment(b.date).format('YYYYMMDD'));

        const yAxisDomain = [dataMin => (Math.floor(dataMin) -1), dataMax => (Math.ceil(dataMax) + 1)];

        return (<ResponsiveContainer width="100%" height={300}>
            <LineChart
                data={sortedData}
                margin={{ top: 20, right: 10, left: -32, bottom: 10 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis minTickGap={1} dataKey="date" tick={<XAxisTick />} />
                <YAxis allowDecimals={false} type={"number"} domain={yAxisDomain} tickCount={10} tick={<YAxisTick />} />
                <Tooltip content={<MyToolTip />} />
                <Line isAnimationActive={false} connectNulls type="monotone" dataKey="fatpercentage" stroke={red[900]} />
                <Line isAnimationActive={false} connectNulls type="monotone" dataKey="waterpercentage" stroke={blue[900]} />
                <Line isAnimationActive={false} connectNulls type="monotone" dataKey="musclemass" stroke={green[900]} />
            </LineChart>
        </ResponsiveContainer>);
    }
}

export default BodyMeasuresGraph;
