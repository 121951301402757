import * as types from './action-types';
import api from '../../api';

export const onLoad = () => (dispatch) => {
    dispatch({
        type: types.UPDATE,
        payload: {
            isLoading: true,
        },
    });
    api.get('bodyMeasure').then(({ data }) => {
        const measures = data.filter(({fatpercentage, waterpercentage, musclemass}) => fatpercentage || waterpercentage || musclemass);

        dispatch({
            type: types.UPDATE,
            payload: {
                isLoading: false,
                measures: measures.map(({date, fatpercentage, waterpercentage, musclemass})=> {
                    return {
                        date,
                        fatpercentage: parseFloat(fatpercentage),
                        waterpercentage: parseFloat(waterpercentage),
                        musclemass: parseFloat(musclemass),
                    }
                })
            },
        });
    });
};

export const onAddMeasures = ({ date, fatpercentage, waterpercentage, musclemass }) => (dispatch) => {
    dispatch({
        type: types.UPDATE,
        payload: {
            isLoading: true,
        },
    });
    // Fetch all food items on load
    const data = {
        date,
        fatpercentage,
        waterpercentage,
        musclemass,
    };
    api.post('bodyMeasure', data).then(({ data }) => {
        dispatch({
            type: types.ADD_MEASURES,
            payload: {
                date: data.date,
                fatpercentage: parseFloat(data.fatpercentage),
                waterpercentage: parseFloat(data.waterpercentage),
                musclemass: parseFloat(data.musclemass),
            },
        });
        dispatch({
            type: types.UPDATE,
            payload: {
                isLoading: false,
            },
        });
    });
};
