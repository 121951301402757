import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {TableCell, TableRow, Button, Grid, Typography, withStyles} from '@material-ui/core';
import { getFoodEntryNutrition } from '../../engine/nutrition';
import './food-entry.css';
import NutritionTable from "../nutrition/nutrition-table";
import NutritionChart from "../nutrition/Nutrition-chart";
import FoodEntryEdit from './Food-entry-edit';
import FoodEntryMove from './Food-entry-move';

const styles = {
};

class FoodEntry extends Component {
    static props = {
        id: PropTypes.number.isRequired,
        weight: PropTypes.number.isRequired,
        time_of_day_id: PropTypes.number,
        food_measure_id: PropTypes.number.isRequired,
        food: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            kcal: PropTypes.string.isRequired,
            fat: PropTypes.string.isRequired,
            protein: PropTypes.string.isRequired,
            carbohydrates: PropTypes.string.isRequired,
        }).isRequired,
        timeOfDayList: PropTypes.arrayOf({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        }),
        isMovable: PropTypes.bool,
        onDeleteFoodEntry: PropTypes.func.isRequired,
        onUpdateFoodEntry: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired,
    };

    static defaultProps = {
        isMovable: true,
    };

    state = {
        isEditing: false,
        isMoving: false,
        isExpanded: false,
    };

    handleDeleteFoodEntry = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const {
            onDeleteFoodEntry,
            id,
        } = this.props;
        onDeleteFoodEntry({id});
    };

    handleUpdateTimeOfDay = ({timeOfDayId}) => {
        const {
            id,
            onUpdateFoodEntry,
        } = this.props;

        onUpdateFoodEntry({
            id,
            timeOfDayId,
        });
    };

    handleToggleEdit = () => {
        this.setState((prevState) => {
            return {
                isEditing: !prevState.isEditing,
            };
        });
    };
    handleToggleMove = () => {
        this.setState((prevState) => {
            return {
                isMoving: !prevState.isMoving,
            };
        });
    };
    handleToggleExpand = () => {
        this.setState((prevState) => {
            return {
                isExpanded: !prevState.isExpanded,
            };
        });
    };

    render() {
        const {
            id,
            food,
            food_measure_id,
            time_of_day_id,
            weight,
            timeOfDayList,
            isMovable,
            onUpdateFoodEntry,
        } = this.props;

        const {
            isEditing,
            isMoving,
            isExpanded,
        } = this.state;

        const {
            kcal,
            fat,
            protein,
            carbohydrates,
            weight: actualWeight,
        } = getFoodEntryNutrition(this.props);

        const formattedWeight = parseFloat(weight).toString().replace('.',',');

        const renderWeight = () => {
            if (food_measure_id) {
                const {
                    name: foodMeasureName,
                    name_plural: foodMeasureNamePlural,
                } = food.measures.find(({id}) => id === food_measure_id);
                return <span>{formattedWeight} {weight >= 2 ? foodMeasureNamePlural : foodMeasureName}</span>;
            } else {
                return <span>{formattedWeight}g</span>;
            }
        };

        return (<Fragment>
            <TableRow className={`food-entry-row${isExpanded ? ' is-expanded' : ''}`} hover onClick={this.handleToggleExpand}>
                <TableCell>
                    {food.name}
                </TableCell>
                <TableCell align="right" className={'table-cell-weight'}>
                    {renderWeight()}
                </TableCell>
                <TableCell align="right" className="table-cell-kcal">
                    {Math.round(kcal)} kcal
                </TableCell>
            </TableRow>
            {isExpanded && (
                <TableRow className="food-entry-row-expansion">
                    <TableCell colSpan={3}>
                        <br/>
                        {isEditing && (
                            <FoodEntryEdit
                                id={id}
                                weight={weight}
                                food_measure_id={food_measure_id}
                                food={food}
                                onUpdateFoodEntry={onUpdateFoodEntry}
                                onClose={this.handleToggleEdit}
                            />
                            )}
                        {isMoving && (
                            <FoodEntryMove
                                timeOfDayList={timeOfDayList}
                                selectedTimeOfDayId={time_of_day_id}
                                onCancel={this.handleToggleMove}
                                onUpdateTimeOfDay={this.handleUpdateTimeOfDay}
                            />
                        )}
                        {!isEditing && !isMoving && (
                            <Fragment>
                                <Button type="button" variant={"outlined"} color={"secondary"} size={"small"} onClick={this.handleToggleEdit}>
                                    Redigér mængde
                                </Button>
                                {isMovable && (<Fragment>
                                    &nbsp;&nbsp;
                                    <Button type="button" variant={"outlined"} size={"small"} onClick={this.handleToggleMove}>
                                        Flyt
                                    </Button>
                                </Fragment>)}
                                &nbsp;&nbsp;
                                <Button type="button" variant={"contained"} size={"small"} onClick={this.handleDeleteFoodEntry}>
                                    Slet
                                </Button>
                                <br/>
                                <br />
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <NutritionTable
                                            heading={`Næring i ${actualWeight}g:`}
                                            carbohydrates={carbohydrates}
                                            fat={fat}
                                            kcal={kcal}
                                            protein={protein}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        {parseInt(kcal) !== 0 && (<React.Fragment>
                                            <Typography variant={"caption"}>
                                                Energifordeling:
                                            </Typography>
                                            <NutritionChart
                                                fat={food.fat}
                                                protein={food.protein}
                                                carbohydrates={food.carbohydrates}
                                            />
                                        </React.Fragment>)}
                                    </Grid>
                                </Grid>
                            </Fragment>
                        )}
                        <br />
                    </TableCell>
                </TableRow>
            )}
        </Fragment>);
    }
}

export default withStyles(styles)(FoodEntry);
