import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BodyMeasuresGraph from './body-measures-graph';
import * as actions from './actions';

function mapStateToProps(state) {
    const {
        bodyMeasures,
    } = state;
    return {
        measures: bodyMeasures.measures,
    };
}


const mapDispatchToProps = (dispatch) => {
    const {
        onLoad,
    } = actions;
    return bindActionCreators({
        onLoad: onLoad,
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(BodyMeasuresGraph);
