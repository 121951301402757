import { connect } from 'react-redux';
import MealPlan from './Meal-plan';
import * as actions from './meal-plan-actions';
import {bindActionCreators} from "redux";

function mapStateToProps(state) {
    const {
        timeOfDayList,
        id,
        foodEntries,
        date,
        isLoading,
    } = state.mealPlan;
    return {
        timeOfDayList,
        id,
        foodEntries,
        date,
        isLoading,
    };
}

const mapDispatchToProps = (dispatch) => {
    const {
        onLoad,
        onChangeDate,
    } = actions;
    return bindActionCreators({
        onLoad: onLoad,
        onChangeDate,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(MealPlan);
