import { connect } from 'react-redux';
import App from './App';

function mapStateToProps(state) {
    const { auth } = state;

    return {
        isLoggedIn: !!auth.token,
    };
}

export default connect(mapStateToProps)(App);
