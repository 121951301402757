import axios from "axios";
import ErrorDialog from './components/core/error-dialog';
import { apiEndpoint } from "./env";
import store from './store';
import ReactDOM from "react-dom";
import React from "react";

const api = {};

const getConfig = () => ({
    headers: {'Authorization': "bearer " + store.getState().auth.token}
});


const onCloseErrorDialog = () => {
    const errorDialogContainer = document.getElementById('apiErrorDialogMounter');
    ReactDOM.unmountComponentAtNode(errorDialogContainer);
}

const onError = (error) => {
    const errorDialogContainer = document.getElementById('apiErrorDialogMounter');
    ReactDOM.render(<ErrorDialog onClose={onCloseErrorDialog} message={error.message} />, errorDialogContainer);
};

api.post = (path, data, withToken = true) => {
    const config =  withToken ? getConfig() : undefined;
    return new Promise((resolve) => {
        axios.post(`${apiEndpoint}/${path}`, data, config)
            .then((response) => {
                resolve(response);
            })
            .catch(onError);
    });
};

api.get = (path, withToken = true) => {
    const config =  withToken ? getConfig() : undefined;
    return new Promise((resolve) => {
        axios.get(`${apiEndpoint}/${path}`, config).then((response) => {
            resolve(response);
        }).catch(onError);
    });
};

api.put = (path, data, withToken = true) => {
    const config =  withToken ? getConfig() : undefined;
    return new Promise((resolve) => {
        axios.put(`${apiEndpoint}/${path}`, data, config).then((response) => {
            resolve(response);
        }).catch(onError);
    });
};

api.delete = (path, withToken = true) => {
    const config =  withToken ? getConfig() : undefined;
    return new Promise((resolve) => {
        axios.delete(`${apiEndpoint}/${path}`, config).then((response) => {
            resolve(response);
        }).catch(onError);
    });
};
export default api;
