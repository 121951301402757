import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import SearchInput from '../core/search-input';
import LoadingSpinner from '../core/loading-spinner';
import FoodListItem from './Food-list-item-connected';
import FoodCreator from './Food-creator';
import {Divider, Typography, withStyles, CircularProgress} from "@material-ui/core";

const styles = theme => ({
    divider: {
        marginTop: theme.spacing(2),
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
    }
});

class FoodList extends Component {
    static props = {
        timeOfDayId: PropTypes.number.isRequired,
        food: PropTypes.arrayOf({
            id: PropTypes.number.isRequired,
        }),
        onCreateFoodEntries: PropTypes.func,
        onSearch: PropTypes.func,
        onCleanup: PropTypes.func,
        onCreateFood: PropTypes.func,
        onGetQuickAddFood: PropTypes.func,
        isLoading: PropTypes.bool,
        isLoadingFoodSuggestions: PropTypes.bool,
        classes: PropTypes.object.isRequired,
    };

    state = {
        searchTerm: '',
        typing: false,
        typingTimeout: 0,
        showAddNewFood: false,
    };

    componentDidMount() {
        const {
            timeOfDayId,
            onGetQuickAddFood,
        } = this.props;
        if (timeOfDayId && onGetQuickAddFood) {
            onGetQuickAddFood({timeOfDayId});
        }
    }

    componentWillUnmount() {
        const {
            onCleanup,
        } = this.props;

        if (onCleanup) {
            onCleanup();
        }
    }

    onSearch = (evt) => {
        const self = this;
        const searchTerm = evt.target.value;

        if (searchTerm.length < 2) {
            return;
        }

        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            searchTerm,
            typing: true,
            typingTimeout: setTimeout(function () {
                self.props.onSearch({ searchTerm });
                self.setState({typing: false});
            }, 1000)
        });
    };

    handleCreateFood = (args) => {
        this.handleToggleShowAddNewFood();
        this.props.onCreateFood(args);
    };

    handleToggleShowAddNewFood = () => {
        this.setState((prevState) => {
            return {
                showAddNewFood: !prevState.showAddNewFood,
            };
        });
    };

    render() {
        const {
            food,
            onCreateFoodEntries,
            isLoading,
            isLoadingFoodSuggestions,
            classes,
        } = this.props;
        const {
            searchTerm,
            showAddNewFood,
            typing,
        } = this.state;

        const hasSearchResults = food.length > 0 && !isLoading;

        return (
            <div className="food-list" style={{textAlign: 'left'}}>
                <SearchInput onChange={this.onSearch} placeholder={"Søg madvare"} />
                <br />
                {isLoadingFoodSuggestions && <LoadingSpinner message={"Henter forslag til madvarer..."} />}
                {(isLoading || typing) && (
                    <div className={classes.progressContainer}>
                        <CircularProgress color={"secondary"} size={20} />
                    </div>
                )}
                {!typing && (<React.Fragment>
                    {hasSearchResults && (
                        <div>
                            {food.map(({ id }) => <FoodListItem key={id} id={id} onCreateFoodEntries={onCreateFoodEntries} />)}
                        </div>
                    )}
                    {searchTerm.length > 0 && !hasSearchResults && !isLoading && <Typography variant={"subtitle2"}> {`...Vi søgte efter "${searchTerm}", men vi fandt ikke noget :-/`}</Typography>}
                </React.Fragment>)}
                <div>
                    <Divider className={classes.divider} />
                    <br/>
                    {!showAddNewFood && <Button size={"small"} variant={"outlined"} onClick={this.handleToggleShowAddNewFood}>Opret ny madvare</Button>}
                    {showAddNewFood && <FoodCreator onCreateFood={this.handleCreateFood} onCancel={this.handleToggleShowAddNewFood} />}
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(FoodList);
