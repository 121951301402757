import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    withStyles,
} from '@material-ui/core';
import moment from "moment/moment";

const styles = theme => ({
    label: {
        fontSize: 12,
        fontFamily: theme.fontFamily,
    },
});

class XAxisTick extends Component {
    static props = {
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        classes: PropTypes.object.isRequired,
        payload: PropTypes.shape({
            value: PropTypes.number.isRequired,
        }).isRequired,
    };

    render() {
        const {
            classes,
            x,
            y,
            payload,
        } = this.props;

        const value = moment(payload.value).format('D[/]M');

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dx={-2} dy={4} fill="#666" transform="rotate(-40)" className={classes.label} textAnchor={"end"}>
                    {value}
                </text>
            </g>
        );
    }
}

export default withStyles(styles)(XAxisTick);
