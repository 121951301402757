import * as types from './food-action-types';
import api from '../../api';

export const onSearch = ({ searchTerm }) => (dispatch) => {
    dispatch({
        type: types.UPDATE,
        payload: {
            isLoading: true,
        },
    });
    const data = {
        searchTerm,
    };
    api.post('food/search', data).then(({ data }) => {
        dispatch({
            type: types.UPDATE,
            payload: {
                list: data,
                isLoading: false,
            },
        });
    });
};

export const onGetQuickAddFood = ({timeOfDayId}) => (dispatch) => {
    dispatch({
        type: types.UPDATE,
        payload: {
            isLoadingFoodSuggestions: true,
        },
    });
    const data = {
        timeOfDayId,
    };
    api.post('food/quickAdd', data).then(({ data }) => {
        dispatch({
            type: types.UPDATE,
            payload: {
                list: Object.values(data),
                isLoadingFoodSuggestions: false,
            },
        });
    });
};

export const onCleanup = () => ({
    type: types.UPDATE,
    payload: {
        list: [],
    },
});

export const onCreateFood = ({ name, kcal, fat, protein, carbohydrates }) => (dispatch) => {
    const data = {
        name,
        kcal,
        fat,
        protein,
        carbohydrates,
    };
    api.post('food', data).then((response) => {
        dispatch({
            type: types.CREATE_FOOD,
            food: response.data,
        });
    });
};
