import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FoodList from './Food-list';
import * as actions from './food-actions';

function mapStateToProps(state) {
    const {
        food,
    } = state;
    return {
        food: food.list,
        isLoading: food.isLoading,
        isLoadingFoodSuggestions: food.isLoadingFoodSuggestions,
    };
}


const mapDispatchToProps = (dispatch) => {
    const {
        onSearch,
        onCleanup,
        onCreateFood,
        onGetQuickAddFood,
    } = actions;
    return bindActionCreators({
        onSearch: onSearch,
        onCleanup: onCleanup,
        onCreateFood: onCreateFood,
        onGetQuickAddFood: onGetQuickAddFood,
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(FoodList);
