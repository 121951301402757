import * as types from './action-types';

export default function exerciseReducer(state = {}, action) {
    switch (action.type) {
        case types.UPDATE:{
            return {
                ...state,
                ...action.payload,
            };
        }
        case types.UPDATE_ENTRY:{
            return {
                ...state,
                entries: state.entries.map((entry) => {
                    if (entry.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return entry;
                    }
                }),
            };
        }
        case types.CREATE_ENTRY:{
            return {
                ...state,
                entries: [
                    ...state.entries,
                    {...action.payload}
                ],
            };
        }
        case types.DELETE_ENTRY:{
            return {
                ...state,
                entries: state.entries.filter(({id}) => id !== action.id),
            };
        }
        default:
            return state;
    }
}
