import * as types from './action-types';
import api from "../../api";

export const onLoad = () => (dispatch) => {
    dispatch({
        type: types.UPDATE,
        payload: {
            isLoading: true,
        },
    });
    api.get('timeOfDay').then(({ data }) => {
        dispatch({
            type: types.UPDATE,
            payload: {
                list: data,
                isLoading: false,
            }
        });
    });
};

export const onCleanup = () => ({
    type: types.UPDATE,
    payload: {
        list: [],
    },
});
