import * as types from './action-types';
import api from '../../api';

export const onLoad = () => (dispatch, getState) => {
    dispatch({
        type: types.UPDATE,
        payload: {
            isLoading: true,
        },
    });
    const state = getState();
    const date = state.mealPlan.date.toDate().toISOString();
    api.get(`exercise?date=${date}`).then(({ data }) => {
        dispatch({
            type: types.UPDATE,
            payload: {
                isLoading: false,
                entries: data,
            },
        });
    });
};

export const onCreateExerciseEntry = ({ name, kcal }) => (dispatch, getState) => {
    dispatch({
        type: types.UPDATE,
        payload: {
            isLoading: true,
        },
    });
    const state = getState();
    const date = state.mealPlan.date.toDate().toISOString();

    const data = {
        date,
        name,
        kcal,
    };
    api.post('exercise', data).then(({ data }) => {
        dispatch({
            type: types.CREATE_ENTRY,
            payload: {
                ...data
            },
        });
        dispatch({
            type: types.UPDATE,
            payload: {
                isLoading: false,
            },
        });
    });
};

export const onUpdateExerciseEntry = ({ id, name, kcal }) => (dispatch, getState) => {
    dispatch({
        type: types.UPDATE,
        payload: {
            isLoading: true,
        },
    });
    const state = getState();
    const date = state.mealPlan.date.toDate().toISOString();

    const data = {
        date,
        name,
        kcal,
    };
    api.put(`exercise/${id}`, data).then(({ data }) => {
        dispatch({
            type: types.UPDATE_ENTRY,
            payload: data,
        });
        dispatch({
            type: types.UPDATE,
            payload: {
                isLoading: false,
            },
        });
    });
};

export const onDeleteExerciseEntry = ({ id }) => (dispatch, getState) => {
    dispatch({
        type: types.UPDATE,
        payload: {
            isLoading: true,
        },
    });
    const state = getState();
    // Fetch all food items on load
    const config = {
        headers: {'Authorization': "bearer " + state.auth.token}
    };
    api.delete(`exercise/${id}`, config).then(() => {
        dispatch({
            type: types.DELETE_ENTRY,
            id,
        });
        dispatch({
            type: types.UPDATE,
            payload: {
                isLoading: false,
            },
        });
    });
};