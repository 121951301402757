import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import BodyWeight from "../components/body-weight/body-weight-connected";
import BodyMeasures from "../components/body-measures/body-measures-connected";

const styles = theme => ({
    root: {
        position: 'relative',
        padding: 16,
        maxWidth: 700,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    card: {
        padding: '10px 20px',
    },
});

class Grafer extends Component {
    static props = {
        classes: PropTypes.object.isRequired,
    };

    render() {
        const {
            classes,
        } = this.props;

        return (
            <div className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card className={classes.card}>
                            <BodyWeight />
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card className={classes.card}>
                            <BodyMeasures />
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Grafer);
