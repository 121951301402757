import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import moment from 'moment';
import 'moment/locale/da';
import { createTheme , MuiThemeProvider } from '@material-ui/core/styles';
import orange from '@material-ui/core/colors/orange';
import teal from '@material-ui/core/colors/teal';
import MomentUtils from '@date-io/moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { textColor } from "./colors";
import './index.css';
import App from './App-container';
import * as serviceWorker from './serviceWorker';
import store from './store';

const theme = createTheme ({
    typography: {
        overline: {
            color: textColor,
        },
        subtitle1: {
            color: textColor,
        },
        subtitle2: {
            color: textColor,
        },
        h6: {
            color: textColor,
        },
        button: {
            color: textColor,
        },
        body1: {
            color: textColor,
        },
        body2: {
            color: textColor,
        },

    },
    palette: {
        primary: {
            main: teal[800],
        },
        secondary: {
            main: orange[800],
        },
    },
    overrides: {
        MuiInput: {
            input: {
                color: textColor,
            },
        },
        MuiDialog: {
          paper: {
              margin: 20,
          }
        },
        MuiExpansionPanelSummary: {
            content: {
              margin: '8px 0',
            },
        },
        MuiTableCell: {
            head: {
                color: textColor,
                borderBottom: 'none',
                backgroundColor: '#F5F7F5',
            },
            root: {
                color: textColor,
            },
            body: {
                color: textColor,
            }
        },
    },
});

moment.locale('da');

ReactDOM.render(
    <Provider store={store}>
        <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <App />
                </LocalizationProvider>
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    </Provider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
