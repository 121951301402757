import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Meals from './Meals';
import * as actions from './actions';

function mapStateToProps(state) {
    const {
        meal,
    } = state;
    return {
        meals: meal.list,
        isLoading: meal.isLoading,
    };
}


const mapDispatchToProps = (dispatch) => {
    const {
        onLoadMeals,
        onCleanupMeals,
    } = actions;
    return bindActionCreators({
        onLoad: onLoadMeals,
        onCleanup: onCleanupMeals,
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(Meals);
