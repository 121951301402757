import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CopyMealPlanTemplateToMealPlan from './copy-meal-plan-template-to-meal-plan';
import * as actions from './actions';

function mapStateToProps(state) {
    const {
        mealPlanTemplate,
    } = state;

    return {
        isCopying: mealPlanTemplate.isCopyingTemplate,
    }
}


const mapDispatchToProps = (dispatch) => {
    const {
        onCopyMealPlanTemplateToMealPlan,
    } = actions;
    return bindActionCreators({
        onCopyMealPlanTemplateToMealPlan,
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(CopyMealPlanTemplateToMealPlan);
