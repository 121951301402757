import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MealPlanTemplate from './Meal-plan-template';
import * as actions from './actions';

function mapStateToProps(state) {
    const {
        timeOfDayList,
        isLoading,
        chosen,
    } = state.mealPlanTemplate;
    return {
        timeOfDayList,
        isLoading,
        foodEntries: chosen ? chosen.food_entries : undefined,
        name: chosen ? chosen.name : undefined,
    };
}


const mapDispatchToProps = (dispatch) => {
    const {
        onCleanupTemplate,
        onLoadTemplate,
        onDeleteTemplate,
        onUpdateTemplateName,
    } = actions;
    return bindActionCreators({
        onCleanup: onCleanupTemplate,
        onLoad: onLoadTemplate,
        onDelete: onDeleteTemplate,
        onUpdateName: onUpdateTemplateName,
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(MealPlanTemplate);
