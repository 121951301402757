import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import {InputAdornment, TextField, Typography} from "@material-ui/core";
import TimeOfDayPicker from "../time-of-day/time-of-day-picker-connected";
import Button from "@material-ui/core/Button";
import LoadingSpinner from "../core/loading-spinner";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import green from "@material-ui/core/es/colors/green";
import MenuItem from "@material-ui/core/MenuItem";
/* eslint-disable react/jsx-no-duplicate-props */

const styles = theme => ({
    root: {
        position: 'relative',
    },
    card: {
        padding: '10px 20px',
    },
    datepicker: {
        width: '100%',
    },
    flexGrid: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    snackbarContent: {
        backgroundColor: green[600],
    },
    snackbarIcon: {
        fontSize: 20,
    },
});

class CopyMealToMealPlanTemplate extends Component {
    static props = {
        mealId: PropTypes.number.isRequired,
        onCopyMealToMealPlanTemplate: PropTypes.func.isRequired,
        onLoadTemplates: PropTypes.func.isRequired,
        templates: PropTypes.arrayOf({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        }),
        isCopyingMeal: PropTypes.bool,
        classes: PropTypes.object.isRequired,
    };

    static defaultProps = {
        templates: [],
    };

    state = {
        selectedTimeOfDayId: 1,
        selectedTemplateId: this.props.templates.length ? this.props.templates[0].id : undefined,
        showSnackbar: false,
    };

    componentDidMount() {
        const {
            onLoadTemplates,
        } = this.props;
        if (onLoadTemplates) {
            onLoadTemplates().then(() => {
                if (this.props.templates.length) {
                    this.setState({
                        selectedTemplateId: this.props.templates[0].id,
                    });
                }
            });
        }
    }

    handleUpdateSelectedTimeOfDay = (evt) => {
        this.setState({
            selectedTimeOfDayId: evt.target.value,
        });
    };

    handleUpdateSelectedTemplate = (evt) => {
        this.setState({
            selectedTemplateId: evt.target.value,
        });
    };

    handleCopyMeal = (evt) => {
        evt.preventDefault();
        const {
            mealId,
            onCopyMealToMealPlanTemplate,
        } = this.props;
        const {
            amount: amountInput,
            template: templateInput,
        } = evt.target;
        const amount = parseInt(amountInput.value);
        const {
            selectedTimeOfDayId,
        } = this.state;
        onCopyMealToMealPlanTemplate({mealId, mealPlanTemplateId: parseInt(templateInput.value), timeOfDayId: selectedTimeOfDayId, amount}).then(()=> {
            this.setState({
                showSnackbar: true,
            });
        });
    };

    handleHideSnackbar = () => {
        this.setState({
            showSnackbar: false,
        });
    };

    render() {
        const {
            isCopyingMeal,
            classes,
            templates,
        } = this.props;

        const {
            selectedTimeOfDayId,
            selectedTemplateId,
            showSnackbar,
        } = this.state;

        if (!templates.length) {
            return null;
        }

        return (
            <Grid item xs={12} className={classes.root}>
                {isCopyingMeal && <LoadingSpinner message={"Kopierer måltid til skabelon"} />}
                <Card className={classes.card}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant={"subtitle1"}>Kopier måltid til skabelon</Typography>
                            <form onSubmit={this.handleCopyMeal}>
                                <TextField
                                    name={"template"}
                                    select
                                    value={selectedTemplateId}
                                    margin={"none"}
                                    label={"Skabelon"}
                                    fullWidth
                                    onChange={this.handleUpdateSelectedTemplate}
                                >
                                    {templates.map(({ id, name }) => (
                                        <MenuItem value={id} key={id}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <br/>
                                <br/>
                                <TimeOfDayPicker
                                    label={"Til tidspunkt"}
                                    selectedTimeOfDayId={selectedTimeOfDayId}
                                    onChange={this.handleUpdateSelectedTimeOfDay}
                                />
                                <br />
                                <br />
                                <TextField
                                    label={"Mængde"}
                                    type="number"
                                    name="amount"
                                    required
                                    fullWidth
                                    defaultValue={100}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    inputProps={{
                                        min: 1,
                                        max: 100,
                                    }}
                                />
                                <br/>
                                <br/>
                                <div className={classes.flexGrid}>
                                    <Button variant={"contained"} color={"primary"} type={"submit"}>
                                        Kopier
                                    </Button>
                                </div>
                            </form>
                        </Grid>
                    </Grid>
                </Card>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    variant={"success"}
                    open={showSnackbar}
                    autoHideDuration={6000}
                    onClose={this.handleHideSnackbar}
                >
                    <SnackbarContent className={classes.snackbarContent}
                         message={"Måltid kopieret"}
                         action={[
                             <IconButton
                                 key="Luk"
                                 aria-label="Luk"
                                 color="inherit"
                                 onClick={this.handleHideSnackbar}
                             >
                                 <CloseIcon className={classes.snackbarIcon} />
                             </IconButton>,
                         ]}
                    />
                </Snackbar>
            </Grid>
        );
    }
}

export default withStyles(styles)(CopyMealToMealPlanTemplate);
