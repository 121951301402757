import * as types from './body-weight-action-types';

export default function bodyWeightReducer(state = {}, action) {
    switch (action.type) {
        case types.UPDATE:{
            return {
                ...state,
                ...action.payload,
            };
        }
        case types.ADD_WEIGHT_MEASURE:{
            return {
                ...state,
                weights: [
                    ...state.weights,
                    action.payload,
                ],
            };
        }
        default:
            return state;
    }
}
