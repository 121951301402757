import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {CircularProgress} from "@material-ui/core";
import './loading-spinner.css';

/**
 * Important!: The parent element must have positon:relative, such that the loading spinner container can be positioned absolute!
 */
export default class LoadingSpinner extends React.PureComponent {

    static propTypes = {
        fixed: PropTypes.bool,
        delay: PropTypes.number, // The number of ms to delay before showing the spinner (ui will still be blocked during the delay)
        overlayBackgroundColor: PropTypes.string, // Defines the background color of the overlay (not the box)
        message: PropTypes.string,
    };

    static defaultProps = {
        delay: 400,
        overlayBackgroundColor: 'rgba(255,255,255,0.7)',
    };

    state = {
        isHidden: true,
    };

    componentDidMount() {
        // Delay the loading spinner layout
        this.delayTimeout = setTimeout(() => {
            this.show();
        }, this.props.delay);
    }

    componentWillUnmount() {
        clearTimeout(this.delayTimeout);
        this.delayTimeout = null;
    }

    delayTimeout = null;

    show = () => {
        this.setState({ isHidden: false });
    };

    handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    render() {
        const {
            overlayBackgroundColor,
            fixed,
            message,
        } = this.props;

        const { isHidden } = this.state;

        const spinnerClasses = classNames('loading-spinner-container', {
            hide: isHidden,
            fixed,
        });

        return (
            <div onClick={this.handleClick} style={{ 'cursor': 'auto' }}>
                <div className={spinnerClasses} style={{ backgroundColor: overlayBackgroundColor }}>
                    <CircularProgress color={"secondary"} size={20} />
                    <div className={"loading-spinner-message"}>
                        {message}
                    </div>
                </div>
            </div>
        );
    }
}
