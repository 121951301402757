import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Typography,
    withStyles,
} from '@material-ui/core';
import moment from "moment/moment";
import Paper from "@material-ui/core/Paper";

const styles = theme => ({
    root: {
        padding: theme.spacing(1),
    },
});

class Tooltip extends Component {
    static props = {
        classes: PropTypes.object.isRequired,
        payload: PropTypes.shape({
            payload: PropTypes.shape({
                date: PropTypes.string.isRequired,
                weight: PropTypes.number.isRequired,
            }).isRequired,
        }).isRequired,
    };

    render() {


        const {
            classes,
            payload,
        } = this.props;

        if (!payload.length) {
            return null;
        }

        const {
            payload: {
                date,
                weight,
            }
        } = payload[0];

        const formattedDate = moment(date).format('LL');
        const formattedWeight = weight.toString().replace('.', ',') + ' kg';
        return (
            <Paper className={classes.root}>
                <Typography variant={"caption"}>
                    {formattedDate}
                </Typography>
                <Typography variant={"body2"}>
                    {formattedWeight}
                </Typography>
            </Paper>
        );
    }
}

export default withStyles(styles)(Tooltip);
