import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {Button, Divider, withStyles} from '@material-ui/core';
import './food-entry.css';
import TimeOfDayPicker from "../time-of-day/time-of-day-picker";
/* eslint-disable react/jsx-no-duplicate-props */

const styles = {
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

class FoodEntryMove extends Component {
    static props = {
        selectedTimeOfDayId: PropTypes.number,
        timeOfDayList: PropTypes.arrayOf({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        }),
        classes: PropTypes.object.isRequired,
        onCancel: PropTypes.func.isRequired,
        onUpdateTimeOfDay: PropTypes.func.isRequired,
    };

    state = {
        selectedTimeOfDayId: this.props.selectedTimeOfDayId,
    };

    handleUpdateSelectedTimeOfDay = (evt) => {
        this.setState({
            selectedTimeOfDayId: evt.target.value,
        });
    };

    handleUpdateTimeOfDay = (event) => {
        event.preventDefault();
        const {
            onUpdateTimeOfDay,
        } = this.props;

        onUpdateTimeOfDay({
            timeOfDayId: this.state.selectedTimeOfDayId,
        });
    };

    render() {
        const {
            timeOfDayList,
            classes,
            onCancel,
        } = this.props;

        const {
            selectedTimeOfDayId,
        } = this.state;


        return (<Fragment>
            <form onSubmit={this.handleUpdateTimeOfDay}>
                <TimeOfDayPicker
                    timeOfDayList={timeOfDayList}
                    selectedTimeOfDayId={selectedTimeOfDayId}
                    onChange={this.handleUpdateSelectedTimeOfDay}
                />
                <br/>
                <br/>
                <div className={classes.buttonsContainer}>
                    <Button type="button" variant={"outlined"} size={"small"} onClick={onCancel}>
                        Fortryd
                    </Button>
                    <Button type="submit" variant={"contained"} color={"primary"} size={"small"}>
                        Flyt
                    </Button>
                </div>
            </form>
            <br />
            <Divider />
        </Fragment>);
    }
}

export default withStyles(styles)(FoodEntryMove);
