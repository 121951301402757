import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Meal from './Meal';
import * as actions from './actions';

function mapStateToProps(state) {
    const {
        meal,
    } = state;

    if (!meal.chosen) {
        return {
            isLoading: meal.isLoading
        }
    }

    const {
        name,
        food_entries,
    } = meal.chosen;

    return {
        isLoading: meal.isLoading,
        name,
        food_entries,
    };
}


const mapDispatchToProps = (dispatch) => {
    const {
        onLoadMeal,
        onCleanupMeal,
        onDeleteMeal,
        onUpdateName,
        onCreateFoodEntries,
        onDeleteFoodEntry,
        onUpdateFoodEntry,
    } = actions;
    return bindActionCreators({
        onLoad: onLoadMeal,
        onCleanup: onCleanupMeal,
        onDelete: onDeleteMeal,
        onUpdateName,
        onCreateFoodEntries,
        onDeleteFoodEntry,
        onUpdateFoodEntry,
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(Meal);
