import * as types from './meal-plan-action-types';

export default function mealPlanReducer(state = { timeOfDayList: []}, action) {
    switch (action.type) {
        case types.UPDATE:{
            return {
                ...state,
                ...action.payload,
            };
        }
        case types.ADD_FOOD_ENTRIES:{
            return {
                ...state,
                foodEntries: [...state.foodEntries, ...action.foodEntries],
            };
        }
        case types.DELETE_FOOD_ENTRY:{
            return {
                ...state,
                foodEntries: state.foodEntries.filter(({id}) => id !== action.id),
            };
        }
        case types.UPDATE_FOOD_ENTRY:{
            return {
                ...state,
                foodEntries: state.foodEntries.map((foodEntry) => {
                    if (foodEntry.id !== action.foodEntry.id) {
                        return foodEntry;
                    }
                    return {
                        ...foodEntry,
                        ...action.foodEntry,
                    }
                }),
            };
        }
        default:
            return state;
    }
}
