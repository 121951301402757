import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import {Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import LoadingSpinner from "../core/loading-spinner";
import NutritionChart from "../nutrition/Nutrition-chart";
import {getFoodEntriesTotalNutrition} from "../../engine/nutrition";

const styles = theme => ({
    root: {
        position: 'relative',
        padding: 16,
        maxWidth: 700,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    card: {
        padding: '10px 20px',
    },
    link: {
        textDecoration: 'none',
    },
    nowrap: {
        whiteSpace: 'nowrap',
    }
});

class Meals extends Component {
    static props = {
        classes: PropTypes.object.isRequired,
        onLoad: PropTypes.func.isRequired,
        onCleanup: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        meals: PropTypes.arrayOf({
            id: PropTypes.number,
            name: PropTypes.string,
        }),
    };

    static defaultProps = {
        meals: [],
    };

    componentDidMount() {
        this.props.onLoad();
    }

    componentWillUnmount() {
        const {
            onCleanup,
        } = this.props;

        if (onCleanup) {
            onCleanup();
        }
    }

    render() {
        const {
            meals,
            classes,
            isLoading,
        } = this.props;

        return (
            <div className={classes.root}>
                {isLoading && <LoadingSpinner />}
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card className={classes.card}>
                            <Typography variant={"h5"}>Måltider</Typography>
                            <br/>
                            <Link to="/nyt-maaltid" className={classes.link}>
                                <Button variant={"outlined"} color="primary" fullWidth >
                                    Nyt måltid
                                </Button>
                            </Link>
                            <br />
                            <br />
                            { meals.length > 0 && (<Fragment>
                                <Typography variant={"caption"}>Eksisterende:</Typography>
                                <List>
                                    {meals.map(({id, name, food_entries}) => {
                                        const {
                                            kcal,
                                            fat,
                                            protein,
                                            carbohydrates,
                                        } = getFoodEntriesTotalNutrition(food_entries);
                                        return (
                                            <Link to={`/maaltid/${id}`} className={classes.link} key={id}>
                                                <ListItem button>
                                                    <ListItemText
                                                        primary={name || "Unavngivet måltid"}
                                                        primaryTypographyProps={ {variant: 'body1'}}
                                                    />
                                                    <Typography variant={"caption"} className={classes.nowrap}>{Math.round(kcal)} kcal&nbsp;</Typography>
                                                    <NutritionChart
                                                        size={"mini"}
                                                        fat={fat}
                                                        protein={protein}
                                                        carbohydrates={carbohydrates}
                                                    />
                                                </ListItem>
                                            </Link>
                                        );
                                    })}
                                </List>
                            </Fragment>)}
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Meals);
