import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import BodyMeasuresTextEntry from '../body-measures-text-entry/body-measures-text-entry';

const styles = theme => ({
    stats: {
        marginTop: 10,
        padding: 10,
    },
});

class BodyMeasuresText extends Component {
    static props = {
        currentWaterpercentage: PropTypes.number,
        maxWaterpercentage: PropTypes.number,
        minWaterpercentage: PropTypes.number,
        currentFatpercentage: PropTypes.number,
        maxFatpercentage: PropTypes.number,
        minFatpercentage: PropTypes.number,
        currentMusclemass: PropTypes.number,
        maxMusclemass: PropTypes.number,
        minMusclemass: PropTypes.number,
        classes: PropTypes.object.isRequired,
    };


    render() {
        const {
            classes,
            currentWaterpercentage,
            maxWaterpercentage,
            minWaterpercentage,
            currentFatpercentage,
            maxFatpercentage,
            minFatpercentage,
            currentMusclemass,
            maxMusclemass,
            minMusclemass,
        } = this.props;

        const showWaterText = !!currentWaterpercentage;
        const showMuscleText = !!currentMusclemass;
        const showFatText = !!currentFatpercentage;

        return (<Paper elevation={2} className={classes.stats}>
            { showWaterText && (
                <BodyMeasuresTextEntry
                    color={blue[900]}
                    name={"Vand"}
                    current={currentWaterpercentage}
                    max={maxWaterpercentage}
                    min={minWaterpercentage}
                />
            )}
            { showMuscleText && (
                <BodyMeasuresTextEntry
                    color={green[900]}
                    name={"Muskelmasse"}
                    current={currentMusclemass}
                    max={maxMusclemass}
                    min={minMusclemass}
                    unit="kg"
                />
            )}
            { showFatText && (
                <BodyMeasuresTextEntry
                    color={red[900]}
                    name={"Fedt"}
                    current={currentFatpercentage}
                    max={maxFatpercentage}
                    min={minFatpercentage}
                />
            )}
        </Paper>);
    }
}

export default withStyles(styles)(BodyMeasuresText);
