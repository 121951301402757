import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    paper: {
        width: 240,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingTop: 0,
        paddingBottom: 20,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        width: 200,
        marginTop: 30,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    textField: {
        width: 200,
    },
    button: {
        marginTop: 20,
    },
});

class LoginForm extends Component {
    static props = {
        onLogin: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired,
    };

    onSubmit = (event) => {
        event.preventDefault();

        const {
            onLogin,
        } = this.props;

        const {
            username: usernameInput,
            password: passwordInput,
        } = event.target;

        onLogin({
            username: usernameInput.value,
            password: passwordInput.value
        });
    };

    render() {
        const {
            classes,
        } = this.props;

        return (
            <Paper elevation={1} className={classes.paper}>
                <form onSubmit={this.onSubmit} className={classes.container}>
                    <TextField
                        id="username"
                        type="text"
                        name="username"
                        label="Brugernavn"
                        className={classes.textField}
                    />
                    <TextField
                        id="password"
                        type="text"
                        name="password"
                        label="Kodeord"
                        className={classes.textField}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        fullWidth
                    >
                        Log ind
                    </Button>
                </form>
            </Paper>
        );
    }
}

export default withStyles(styles)(LoginForm);
