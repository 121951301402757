import * as types from '../food/food-action-types';
import api from '../../api';

export const onCreateFoodMeasure = ({ foodId, weight, name, namePlural }) => (dispatch) => {
    const data = {
        foodId,
        weight,
        name,
        namePlural,
    };
    return new Promise((resolve) => {
        api.post('foodMeasure', data).then(({ data }) => {
            dispatch({
                type: types.CREATE_FOOD_MEASURE,
                foodId,
                foodMeasure: data,
            });
            resolve();
        });
    });
};
