import { connect } from 'react-redux';
import CreateExerciseEntryDialog from './Create-exercise-entry-dialog';
import * as actions from "./actions";
import {bindActionCreators} from "redux";

function mapStateToProps() {
    return {};
}

const mapDispatchToProps = (dispatch) => {
    const {
        onCreateExerciseEntry,
    } = actions;
    return bindActionCreators({
        onCreateExerciseEntry: onCreateExerciseEntry,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateExerciseEntryDialog);
