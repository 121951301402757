import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TimeSlot from './Time-slot-connected';
import { DatePicker } from '@material-ui/pickers';
import Exercise from '../exercise/Exercise-connected';
import LoadingSpinner from "../core/loading-spinner";
import FoodEntriesNutrition from "../food-entries-nutrition/Food-entries-nutrition";

const styles = theme => ({
    root: {
        position: 'relative',
        padding: 16,
        maxWidth: 700,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    paper: {
        padding: '0px 20px 10px',
        textAlign: 'center',
    },
    flexGrid: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    datepicker: {
        minWidth: 220,
    }
});

class MealPlan extends Component {
    static props = {
        onLoad: PropTypes.func.isRequired,
        id: PropTypes.number,
        date: PropTypes.object.isRequired,
        timeOfDayList: PropTypes.arrayOf({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired,
        foodEntries: PropTypes.object,
        onChangeDate: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        classes: PropTypes.object.isRequired,
    };

    static defaultProps = {
        foodEntries: [],
    };

    componentDidMount() {
        this.props.onLoad();
    }

    handleDayMinusMinus = () => {
        const {
            date,
            onChangeDate,
        } = this.props;

        onChangeDate({date: date.clone().subtract(1, 'days')});
    };

    handleDayPlusPlus = () => {
        const {
            date,
            onChangeDate,
        } = this.props;
        onChangeDate({date: date.clone().add(1, 'days')});
    };

    handleDateChange = (date) => {
        const {
            onChangeDate,
        } = this.props;
        onChangeDate({date});
    };

    render() {
        const {
            id,
            timeOfDayList,
            date,
            foodEntries,
            classes,
            isLoading,
        } = this.props;

        return (
            <div key={id} className={classes.root}>
                {isLoading && <LoadingSpinner />}
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper elevation={1} className={classes.paper}>
                            <div className={classes.flexGrid}>
                                <IconButton aria-label="Previous" onClick={this.handleDayMinusMinus}>
                                    <ArrowLeftIcon color={"primary"}/>
                                </IconButton>
                                <DatePicker
                                    margin="normal"
                                    value={date}
                                    showTodayButton
                                    format={'dddd [d.] LL'}
                                    onChange={this.handleDateChange}
                                    className={classes.datepicker}
                                />
                                <IconButton aria-label="Next" onClick={this.handleDayPlusPlus}>
                                    <ArrowRightIcon color={"primary"} />
                                </IconButton>
                            </div>
                            {timeOfDayList.map(({ id, name }) => (
                                <TimeSlot
                                    key={id}
                                    id={id}
                                    name={name}
                                    timeOfDayList={timeOfDayList}
                                />
                            ))}
                            <Exercise key={date} />
                        </Paper>
                    </Grid>
                    <FoodEntriesNutrition foodEntries={foodEntries} showWater />
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(MealPlan);
