import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { Typography} from "@material-ui/core";
import { DatePicker } from '@material-ui/pickers';
import moment from "moment";
import Button from "@material-ui/core/Button";
import LoadingSpinner from "../core/loading-spinner";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import green from "@material-ui/core/es/colors/green";

const styles = theme => ({
    root: {
        position: 'relative',
    },
    card: {
        padding: '10px 20px',
    },
    datepicker: {
        width: '100%',
    },
    flexGrid: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    snackbarContent: {
        backgroundColor: green[600],
    },
    snackbarIcon: {
        fontSize: 20,
    },
});

class CopyMealPlanTemplateToMealPlan extends Component {
    static props = {
        mealPlanTemplateId: PropTypes.number.isRequired,
        onCopyMealPlanTemplateToMealPlan: PropTypes.func.isRequired,
        isCopying: PropTypes.bool,
        classes: PropTypes.object.isRequired,
    };

    state = {
        date: moment(),
        showSnackbar: false,
    };

    handleDateChange = (date) => {
        this.setState({
            date,
        });
    };

    handleCopy = () => {
        const {
            onCopyMealPlanTemplateToMealPlan,
            mealPlanTemplateId,
        } = this.props;
        const {
            date,
        } = this.state;
        onCopyMealPlanTemplateToMealPlan({mealPlanTemplateId, date}).then(()=> {
            this.setState({
                showSnackbar: true,
            });
        });
    };

    handleHideSnackbar = () => {
        this.setState({
            showSnackbar: false,
        });
    };

    render() {
        const {
            isCopying,
            classes,
        } = this.props;

        const {
            date,
            showSnackbar,
        } = this.state;

        return (
            <Grid item xs={12} className={classes.root}>
                {isCopying && <LoadingSpinner message={"Kopierer skabelon til kostplan"} />}
                <Card className={classes.card}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant={"subtitle1"}>Kopier skabelon til kostplan</Typography>
                            <DatePicker
                                label={"Til dato"}
                                margin="normal"
                                value={date}
                                format={'LL'}
                                showTodayButton
                                onChange={this.handleDateChange}
                                className={classes.datepicker}
                            />
                            <br />
                            <br />
                            <div className={classes.flexGrid}>
                                <Button variant={"contained"} color={"primary"} onClick={this.handleCopy}>
                                    Kopier
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Card>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    variant={"success"}
                    open={showSnackbar}
                    autoHideDuration={6000}
                    onClose={this.handleHideSnackbar}
                >
                    <SnackbarContent className={classes.snackbarContent}
                         message={"Skabelon kopieret"}
                         action={[
                             <IconButton
                                 key="Luk"
                                 aria-label="Luk"
                                 color="inherit"
                                 onClick={this.handleHideSnackbar}
                             >
                                 <CloseIcon className={classes.snackbarIcon} />
                             </IconButton>,
                         ]}
                    />
                </Snackbar>
            </Grid>
        );
    }
}

export default withStyles(styles)(CopyMealPlanTemplateToMealPlan);
