import { connect } from 'react-redux';
import FoodListItem from './Food-list-item';

function mapStateToProps(state, ownProps) {
    const {
        id,
    } = ownProps;
    const {
        food,
    } = state;
    const foodItem = food.list.find((item) => item.id === id);
    const {
        name,
        kcal,
        fat,
        protein,
        carbohydrates,
        measures,
    } = foodItem;
    return {
        name,
        kcal,
        fat,
        protein,
        carbohydrates,
        measures,
    };
}

export default connect(mapStateToProps)(FoodListItem);
