import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = {
    content: {
        padding: 20,
        paddingTop: 0,
    }
};

class ErrorDialog extends Component {
    static props = {
        onClose: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired,
        message: PropTypes.string,
    };

    render() {
        const {
            onClose,
            classes,
            message,
        } = this.props;

        return (
            <Dialog open onClose={onClose}>
                <DialogTitle>Der opstod en fejl! :-(</DialogTitle>
                <div className={classes.content}>
                    {message}
                </div>
            </Dialog>
        );
    }
}

export default withStyles(styles)(ErrorDialog);
