import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TimeOfDayPicker from './time-of-day-picker';
import * as actions from './actions';

function mapStateToProps(state) {
    const {
        timeOfDay,
    } = state;
    return {
        timeOfDayList: timeOfDay.list,
        isLoading: timeOfDay.isLoading,
    };
}


const mapDispatchToProps = (dispatch) => {
    const {
        onLoad,
        onCleanup,
    } = actions;
    return bindActionCreators({
        onLoad: onLoad,
        onCleanup: onCleanup,
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(TimeOfDayPicker);
