import { connect } from 'react-redux';
import BodyWeightText from './body-weight-text';
import moment from "moment";

function mapStateToProps(state) {
    const {
        bodyWeight,
    } = state;
    const hasData = bodyWeight.weights && bodyWeight.weights.length > 0;
    const currentWeight = hasData ? bodyWeight.weights.slice(0).sort((weight1, weight2) => moment(weight1.date).isAfter(moment(weight2.date)) ? -1 : 1)[0].weight : undefined;
    const maxWeight = hasData ? bodyWeight.weights.slice(0).sort((weight1, weight2) => weight1.weight > weight2.weight ? -1 : 1)[0].weight : undefined;
    const minWeight = hasData ? bodyWeight.weights.slice(0).sort((weight1, weight2) => weight1.weight < weight2.weight ? -1 : 1)[0].weight : undefined;

    return {
        currentWeight,
        maxWeight,
        minWeight,
    };
}

export default connect(mapStateToProps)(BodyWeightText);
