import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CreateSomethingWithAName from '../core/create-something-with-a-name';
import { onCreateMeal, onCleanupMeal } from './actions';


export default function CreateMeal() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleCleanup = () => {
        dispatch(onCleanupMeal())
    }

    const handleCreate = ({ name }) => {
        dispatch(onCreateMeal({ name })).then(({data}) => {
            navigate(`/maaltid/${data.id}`);
        });
    }

    return (
        <CreateSomethingWithAName
            heading={'Opret nyt måltid'}
            cancelLink={'/maaltid'}
            onCleanup={handleCleanup}
            onSave={handleCreate}
        />
    )
}
