import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CreateSomethingWithAName from '../core/create-something-with-a-name';
import { onCleanupTemplate, onCreateTemplate } from './actions';

export default function CreateMealPlanTemplate() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleCleanup = () => {
        dispatch(onCleanupTemplate())
    }

    const handleCreateTemplate = ({ name }) => {
        dispatch(onCreateTemplate({ name })).then(({data}) => {
            navigate(`/skabelon/${data.id}`);
        });
    }

    return (
        <CreateSomethingWithAName
            heading={'Opret ny kostplan-skabelon'}
            cancelLink={'/skabeloner'}
            onCleanup={handleCleanup}
            onSave={handleCreateTemplate}
        />
    )
}
