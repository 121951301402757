import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {Button, TextField, InputAdornment, Divider, withStyles} from '@material-ui/core';
import './food-entry.css';
/* eslint-disable react/jsx-no-duplicate-props */

const styles = {
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

class FoodEntryEdit extends Component {
    static props = {
        id: PropTypes.number.isRequired,
        weight: PropTypes.number.isRequired,
        food_measure_id: PropTypes.number.isRequired,
        food: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            kcal: PropTypes.string.isRequired,
            fat: PropTypes.string.isRequired,
            protein: PropTypes.string.isRequired,
            carbohydrates: PropTypes.string.isRequired,
        }).isRequired,
        onUpdateFoodEntry: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired,
    };

    handleUpdateFoodEntry = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const {
            id,
            onUpdateFoodEntry,
            onClose,
        } = this.props;

        const {
            weight: weightInput,
        } = event.target;

        onClose();
        onUpdateFoodEntry({
            id,
            weight: weightInput.value,
        });
    };

    render() {
        const {
            food,
            food_measure_id,
            weight,
            classes,
            onClose,
        } = this.props;


        return (<Fragment>
            <form onSubmit={this.handleUpdateFoodEntry}>
                { food_measure_id
                    ? food.measures.map(({ id, name, weight: foodMeasureWeight }) => {
                        if (food_measure_id !== id) {return null}
                        return (
                            <TextField
                                defaultValue={weight}
                                key={id}
                                label={`${name} (${foodMeasureWeight}g)`}
                                fullWidth
                                type="number"
                                name={"weight"}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">stk</InputAdornment>,
                                }}
                                inputProps={{
                                    step: "any",
                                    min: 0,
                                }}
                            />
                        );
                    })
                    : (<TextField
                        name="weight"
                        type="number"
                        defaultValue={parseInt(weight)}
                        margin={"none"}
                        fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position="end">g</InputAdornment>,
                        }}
                    />)
                }
                <br/>
                <br/>
                <div className={classes.buttonsContainer}>
                    <Button type="button" variant={"outlined"} size={"small"} onClick={onClose}>
                        Fortryd
                    </Button>
                    <Button type="submit" variant={"contained"} color={"primary"} size={"small"}>
                        Gem
                    </Button>
                </div>
            </form>
            <br />
            <Divider />
        </Fragment>);
    }
}

export default withStyles(styles)(FoodEntryEdit);
