import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import BodyMeasuresTextEntry from '../body-measures-text-entry/body-measures-text-entry';

const styles = theme => ({
    stats: {
        marginTop: 10,
        padding: 10,
    },
    animationContainer: {
        position: 'relative',
        height: 40,
    }
});

class BodyWeightText extends Component {
    static props = {
        currentWeight: PropTypes.number,
        maxWeight: PropTypes.number,
        minWeight: PropTypes.number,
        classes: PropTypes.object.isRequired,
    };


    render() {
        const {
            classes,
            currentWeight,
            maxWeight,
            minWeight,
        } = this.props;

        return (<Fragment>
            <Paper elevation={2} className={classes.stats}>
                <BodyMeasuresTextEntry
                    name={"Nuværende"}
                    current={currentWeight}
                    max={maxWeight}
                    min={minWeight}
                    unit={" kg"}
                />
            </Paper>
        </Fragment>);
    }
}

export default withStyles(styles)(BodyWeightText);
