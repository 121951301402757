import { connect } from 'react-redux';
import Exercise from './Exercise';
import * as actions from "./actions";
import {bindActionCreators} from "redux";

function mapStateToProps(state) {
    const {
        exercise,
    } = state;

    return {
        exerciseEntries: exercise.entries,
        isLoading: exercise.isLoading,
    };
}

const mapDispatchToProps = (dispatch) => {
    const {
        onLoad,
    } = actions;
    return bindActionCreators({
        onLoad: onLoad,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Exercise);
