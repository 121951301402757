import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    withStyles,
} from '@material-ui/core';

const styles = theme => ({
    label: {
        fontSize: 12,
        fontFamily: theme.fontFamily,
    },
});

class ReferenceLineLabel extends Component {
    static props = {
        label: PropTypes.string.isRequired,
        fill: PropTypes.string.isRequired,
        classes: PropTypes.object.isRequired,
        viewBox: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }).isRequired,
    };

    render() {
        const {
            label,
            viewBox,
            classes,
            fill,
        } = this.props;
        return (
            <text x={viewBox.x +1} y={viewBox.y-2} fill={fill} className={classes.label}>
                {label}
            </text>
        );
    }
}

export default withStyles(styles)(ReferenceLineLabel);
