import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogContent,
    TextField, InputAdornment, Button
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import '../time-slot/time-slot.css';
import DialogTitle from "@material-ui/core/DialogTitle";
/* eslint-disable react/jsx-no-duplicate-props */

const styles = {
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

class CreateExerciseEntryDialog extends Component {
    static props = {
        open: PropTypes.bool,
        onClose: PropTypes.func.isRequired,
        onCreateExerciseEntry: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired,
    };

    handleCreateExerciseEntry = (event) => {
        event.preventDefault();
        const name = event.target.name.value;
        const kcal = event.target.kcal.value;
        const {
            onClose,
            onCreateExerciseEntry,
        } = this.props;
        onClose();
        onCreateExerciseEntry({ name, kcal });
    };

    render() {
        const {
            classes,
            open,
            onClose,
        } = this.props;

        return (
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>Indtast motion</DialogTitle>
                <DialogContent>
                    <form onSubmit={this.handleCreateExerciseEntry}>
                        <TextField
                            name="name"
                            type="text"
                            margin={"none"}
                            fullWidth
                            label={"Navn"}
                            required
                        />
                        <TextField
                            name="kcal"
                            type="number"
                            margin={"none"}
                            fullWidth
                            label={"Kcal"}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">kcal</InputAdornment>,
                            }}
                            inputProps={{
                                step: "any",
                                min: 0,
                            }}
                            required
                        />
                        <br/>
                        <br/>
                        <div className={classes.buttonsContainer}>
                            <Button type="button" variant={"outlined"} size={"small"} onClick={onClose}>
                                Fortryd
                            </Button>
                            <Button type="submit" variant={"contained"} color={"primary"} size={"small"}>
                                Gem
                            </Button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withStyles(styles)(CreateExerciseEntryDialog);
