import { connect } from 'react-redux';
import UpdateExerciseEntryDialog from './Update-exercise-entry-dialog';
import * as actions from "./actions";
import {bindActionCreators} from "redux";

function mapStateToProps() {
    return {};
}

const mapDispatchToProps = (dispatch) => {
    const {
        onUpdateExerciseEntry,
        onDeleteExerciseEntry,
    } = actions;
    return bindActionCreators({
        onUpdateExerciseEntry: onUpdateExerciseEntry,
        onDeleteExerciseEntry: onDeleteExerciseEntry,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateExerciseEntryDialog);
