import * as types from './food-action-types';

export default function foodReducer(state = {}, action) {
    switch (action.type) {
        case types.UPDATE:{
            return {
                ...state,
                ...action.payload,
            };
        }
        case types.CREATE_FOOD:{
            return {
                ...state,
                list: [ ...state.list, action.food ],
            };
        }
        case types.CREATE_FOOD_MEASURE:{
            return {
                ...state,
                list: state.list.map((food) => {
                    if (food.id !== action.foodId) {
                        return food;
                    } else {
                        return {
                            ...food,
                            measures: [...food.measures, action.foodMeasure],
                        }
                    }
                })
            };
        }
        default:
            return state;
    }
}
