import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, DialogContent, InputAdornment, TextField, withStyles} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DatePicker } from '@material-ui/pickers';
import moment from "moment";
/* eslint-disable react/jsx-no-duplicate-props */

const styles = theme => ({
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    datepicker: {
        width: '100%',
    }
});

class AddWeightDialog extends Component {
    static props = {
        weights: PropTypes.arrayOf({
            date: PropTypes.string.isRequired,
            weight: PropTypes.string.isRequired,
        }),
        onAddWeight: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired,
        classes: PropTypes.object.isRequired,
    };

    state = {
        date: moment(),
        weight: undefined,
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const {
            onAddWeight,
            onClose,
        } = this.props;
        const {
            date,
            weight,
        } = this.state;
        onAddWeight({weight, date});
        onClose();
    };

    handleDateChange = (date) => {
        this.setState({date});
    };

    handleWeightChange = (event) => {
        this.setState({
            weight: event.target.value,
        });
    };

    render() {
        const {
            weights,
            onClose,
            open,
            classes,
        } = this.props;

        const {
            date,
            weight,
        } = this.state;

        const weightForSelectedDate = weights.find((weight) => moment(weight.date).isSame(date, 'day'));
        const formattedWeightForSelectedDate = weightForSelectedDate ? weightForSelectedDate.weight : '';

        return (<Dialog onClose={onClose} open={open}>
            <DialogTitle>Indtast vægt</DialogTitle>
            <DialogContent>
                <form onSubmit={this.handleSubmit}>
                    <DatePicker
                        margin="normal"
                        value={date}
                        format={'LL'}
                        onChange={this.handleDateChange}
                        className={classes.datepicker}
                        showTodayButton
                        label={"Dato"}
                    />
                    <TextField
                        onChange={this.handleWeightChange}
                        name="weight"
                        type="number"
                        margin={"none"}
                        fullWidth
                        label={"Vægt"}
                        value={weight || formattedWeightForSelectedDate}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                        }}
                        inputProps={{
                            step: "any",
                            min: 0,
                        }}
                        required
                    />
                    <br/>
                    <br/>
                    <div className={classes.buttonsContainer}>
                        <Button type="button" variant={"outlined"} size={"small"} onClick={onClose}>
                            Fortryd
                        </Button>
                        <Button type="submit" variant={"contained"} color={"primary"} size={"small"}>
                            Gem
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>);
    }
}

export default withStyles(styles)(AddWeightDialog);
