import * as types from './auth-action-types';
import jwtDecode from "jwt-decode";

export default function authReducer(state = '', action) {
    switch (action.type) {
        case types.SET_AUTH_TOKEN:{
            const decodedToken = jwtDecode(action.token);
            return {
                ...state,
                token: action.token,
                id: decodedToken.sub,
            }
        }
        case types.REMOVE_AUTH_TOKEN:{
            return {}
        }
        default:
            return state;
    }
}
