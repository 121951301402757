import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {CircularProgress, Typography, withStyles} from "@material-ui/core";
import BodyWeightGraph from './body-weight-graph-connected';
import BodyWeightText from './body-weight-text-connected';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import AddWeightDialog from './add-weight-dialog-connected';

const styles = theme => ({
    root: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    addButton: {
        marginTop: -7,
    },
    center: {
        textAlign: 'center',
    }
});

class BodyWeight extends Component {
    static props = {
        isLoading: PropTypes.bool.isRequired,
        hasData: PropTypes.bool.isRequired,
        onLoad: PropTypes.func.isRequired,
        onAddWeightMeasure: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired,
    };

    componentDidMount() {
        this.props.onLoad();
    }

    state = {
        showAddWeight: false,
    };

    handleToggleAddWeight = () => {
        this.setState((prevState) => {
            return {
                showAddWeight: !prevState.showAddWeight,
            };
        });
    };

    render() {
        const {
            classes,
            isLoading,
            hasData,
            onAddWeightMeasure,
        } = this.props;
        const {
            showAddWeight,
        } = this.state;

        if (isLoading) {
            return (<div className={classes.progressContainer}>
                <CircularProgress color={"secondary"} size={20} />
            </div>);
        }

        return (<div className={classes.root}>
            <div className={classes.flexContainer}>
                <Typography variant={"subtitle1"}>Vægt</Typography>
                <Fab size={"small"} color={'primary'} onClick={this.handleToggleAddWeight} className={classes.addButton}>
                    <AddIcon />
                </Fab>
            </div>
            {!hasData && (<Fragment>
                <br/>
                <Typography variant={"body1"}>Indtast din vægt, hvis du har lyst til at se en vægt-graf her.</Typography>
                <Typography variant={"caption"}>...Eller lad være hvis du er ligeglad med din vægt (good for you!).</Typography>
            </Fragment>)}
            {hasData && <BodyWeightGraph />}
            {hasData && <BodyWeightText />}
            <AddWeightDialog
                open={showAddWeight}
                onAddWeight={onAddWeightMeasure}
                onClose={this.handleToggleAddWeight}
            />
        </div>);
    }
}

export default withStyles(styles)(BodyWeight);
