import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import {TextField, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const styles = theme => ({
    root: {
        position: 'relative',
        padding: 16,
        maxWidth: 700,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    card: {
        padding: '20px',
    },
    flexGrid: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    link: {
        textDecoration: 'none',
    },
});

class CreateSomethingWithAName extends Component {
    static props = {
        onSave: PropTypes.func.isRequired,
        onCleanup: PropTypes.func.isRequired,
        heading: PropTypes.string.isRequired,
        classes: PropTypes.object.isRequired,
        cancelLink: PropTypes.string.isRequired,
    };

    state = {
        name: '',
    };

    componentWillUnmount() {
        const {
            onCleanup,
        } = this.props;

        if (onCleanup) {
            onCleanup();
        }
    }

    handleNameChange = (event) => {
        this.setState({
            name: event.target.value,
        });
    };

    handleSave = (event) => {
        event.preventDefault();
        const {
            name,
        } = this.state;
        this.props.onSave({ name });
    };


    render() {
        const {
            heading,
            classes,
            cancelLink,
        } = this.props;

        const {
            name,
        } = this.state;

        return (
            <div className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card className={classes.card}>
                            <Typography variant={"h5"}>{heading}</Typography>
                            <form onSubmit={this.handleSave}>
                                <TextField
                                    autoFocus
                                    label={"Navn"}
                                    defaultValue={name}
                                    required
                                    fullWidth
                                    onChange={this.handleNameChange}
                                />
                                <br/>
                                <br/>
                                <div className={classes.flexGrid}>
                                    <Link to={cancelLink} className={classes.link}>
                                        <Button
                                            variant={"outlined"}
                                        >
                                            Fortryd
                                        </Button>
                                    </Link>
                                    <Button
                                        type="submit"
                                        color={"primary"}
                                        variant={"contained"}
                                    >
                                        Videre
                                    </Button>
                                </div>
                            </form>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(CreateSomethingWithAName);
