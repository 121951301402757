import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CopyMealToMealPlanTemplate from './copy-meal-to-meal-plan-template';
import * as actions from './actions';
import * as templateActions from '../meal-plan-template/actions';

function mapStateToProps(state) {
    const {
        meal,
        mealPlanTemplate,
    } = state;

    return {
        isCopyingMeal: meal.isCopyingMealToTemplate,
        templates: mealPlanTemplate ? mealPlanTemplate.list : undefined,
    }
}


const mapDispatchToProps = (dispatch) => {
    const {
        onCopyMealToMealPlanTemplate,
    } = actions;
    const {
        onLoadTemplates,
    } = templateActions;
    return bindActionCreators({
        onCopyMealToMealPlanTemplate,
        onLoadTemplates,
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(CopyMealToMealPlanTemplate);
