import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, DialogContent, InputAdornment, TextField, withStyles} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DatePicker } from '@material-ui/pickers';
import moment from "moment";
/* eslint-disable react/jsx-no-duplicate-props */

const styles = theme => ({
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    datepicker: {
        width: '100%',
    }
});

class AddBodyMeasuresDialog extends Component {
    static props = {
        onAddMeasures: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired,
        measures: PropTypes.arrayOf({
            date: PropTypes.string.isRequired,
            fatpercentage: PropTypes.string.isRequired,
            waterpercentage: PropTypes.string.isRequired,
            musclemass: PropTypes.string.isRequired,
        }),
        classes: PropTypes.object.isRequired,
    };

    static defaultProps = {
        measures: [],
    };

    state = {
        date: moment(),
        fatpercentage: undefined,
        waterpercentage: undefined,
        musclemass: undefined,
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const {
            onAddMeasures,
            onClose,
        } = this.props;
        const {
            date,
        } = this.state;
        const fatpercentage = event.target.fatpercentage.value;
        const waterpercentage = event.target.waterpercentage.value;
        const musclemass = event.target.musclemass.value;
        onAddMeasures({fatpercentage, waterpercentage, musclemass, date});
        onClose();
    };

    handleDateChange = (date) => {
        this.setState({
            date,
            fatpercentage: undefined,
            waterpercentage: undefined,
            musclemass: undefined,
        });
    };

    handleFatpercentageChange = (event) => {
        this.setState({
            fatpercentage: event.target.value,
        });
    };

    handleWaterpercentageChange = (event) => {
        this.setState({
            waterpercentage: event.target.value,
        });
    };

    handleMusclemassChange = (event) => {
        this.setState({
            musclemass: event.target.value,
        });
    };

    render() {
        const {
            onClose,
            open,
            classes,
            measures,
        } = this.props;

        const {
            date,
            fatpercentage,
            waterpercentage,
            musclemass,
        } = this.state;

        const measuresForSelectedDate = measures.find((measure) => moment(measure.date).isSame(date, 'day'));
        const fatpercentageForSelectedDate = (measuresForSelectedDate && measuresForSelectedDate.fatpercentage) ? measuresForSelectedDate.fatpercentage : '';
        const waterpercentageForSelectedDate = (measuresForSelectedDate && measuresForSelectedDate.waterpercentage) ? measuresForSelectedDate.waterpercentage : '';
        const musclemassForSelectedDate = (measuresForSelectedDate && measuresForSelectedDate.musclemass) ? measuresForSelectedDate.musclemass : '';

        return (<Dialog onClose={onClose} open={open}>
            <DialogTitle>Indtast kropsmålinger</DialogTitle>
            <DialogContent>
                <form onSubmit={this.handleSubmit}>
                    <DatePicker
                        margin="normal"
                        value={date}
                        format={'LL'}
                        onChange={this.handleDateChange}
                        className={classes.datepicker}
                        showTodayButton
                        label={"Dato"}
                    />
                    <TextField
                        name="fatpercentage"
                        type="number"
                        margin={"none"}
                        fullWidth
                        onChange={this.handleFatpercentageChange}
                        label={"Fedtprocent"}
                        value={fatpercentage || fatpercentageForSelectedDate}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        inputProps={{
                            step: "any",
                            min: 0,
                        }}
                    />
                    <TextField
                        name="waterpercentage"
                        type="number"
                        margin={"none"}
                        fullWidth
                        onChange={this.handleWaterpercentageChange}
                        label={"Vandprocent"}
                        value={waterpercentage || waterpercentageForSelectedDate}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        inputProps={{
                            step: "any",
                            min: 0,
                        }}
                    />
                    <TextField
                        name="musclemass"
                        type="number"
                        margin={"none"}
                        fullWidth
                        onChange={this.handleMusclemassChange}
                        label={"Muskelmasse"}
                        value={musclemass || musclemassForSelectedDate}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                        }}
                        inputProps={{
                            step: "any",
                            min: 0,
                        }}
                    />
                    <br/>
                    <br/>
                    <div className={classes.buttonsContainer}>
                        <Button type="button" variant={"outlined"} size={"small"} onClick={onClose}>
                            Fortryd
                        </Button>
                        <Button type="submit" variant={"contained"} color={"primary"} size={"small"}>
                            Gem
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>);
    }
}

export default withStyles(styles)(AddBodyMeasuresDialog);
