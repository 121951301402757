import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from "@material-ui/core/Drawer";
import { FolderSpecial, RoomService, Restaurant, Timeline } from '@material-ui/icons';
import List from "@material-ui/core/List";
import AppMenuLink from './App-menu-link';

const styles = {
    list: {
        width: 250,
    },
};

class AppMenu extends Component {
    static props = {
        classes: PropTypes.object.isRequired,
        isOpen: PropTypes.bool,
        onClose: PropTypes.func.isRequired,
    };

    render() {
        const {
            isOpen,
            onClose,
            classes,
        } = this.props;

        return (
            <Drawer open={isOpen} onClose={onClose}>
                <div
                    tabIndex={0}
                    role="button"
                    onClick={onClose}
                    onKeyDown={onClose}
                >
                    <List className={classes.list}>
                        <AppMenuLink
                            to={"/"}
                            icon={<Restaurant />}
                            text={"Kostplan"}
                        />
                        <AppMenuLink
                            to={"/skabeloner"}
                            icon={<FolderSpecial />}
                            text={"Skabeloner"}
                        />
                        <AppMenuLink
                            to={"/maaltid"}
                            icon={<RoomService />}
                            text={"Måltider"}
                        />
                        <AppMenuLink
                            to={"/grafer"}
                            icon={<Timeline />}
                            text={"Grafer"}
                        />
                    </List>
                </div>
            </Drawer>
        );
    }
}

export default withStyles(styles)(AppMenu);
