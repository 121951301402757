import React, {useEffect, useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import LoadingSpinner from "../core/loading-spinner";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TimeSlot from "./Time-slot-connected";
import FoodEntriesNutrition from "../food-entries-nutrition/Food-entries-nutrition";
import {TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {Link, useNavigate, useParams} from "react-router-dom";
import CopyMealPlanTemplateToMealPlan from "./copy-meal-plan-template-to-meal-plan-connected";

const styles = {
    root: {
        position: 'relative',
        padding: 16,
        maxWidth: 700,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    paper: {
        padding: '20px',
        textAlign: 'center',
    },
    actionButtonsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    headline: {
        cursor: 'pointer',
    },
    nameForm: {
        display: 'flex',
        marginBottom: 20,
        position: 'relative',
    },
    nameFormTextField: {
        flexGrow: 1,
        marginRight: 10,
    },
    link: {
        textDecoration: 'none',
    },
};

function MealPlanTemplate({
    name,
    timeOfDayList = [],
    foodEntries = [],
    isLoading,
    onLoad,
    onCleanup,
    onUpdateName,
    onDelete,
    classes,
}) {
    const [isEditingName, setIsEditingName] = useState(false);
    const [isSavingName, setIsSavingName] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (onLoad) {
            onLoad({ id });
        }

        return () => {
            if (onCleanup) {
                onCleanup();
            }
        }
    }, [onLoad, id, onCleanup]);

    const handleDelete = () => {
        onDelete({ id }).then(() => {
            navigate('/skabeloner');
        });
    };

    const handleToggleEditName = () => {
        setIsEditingName((prev) => !prev);
    };

    const handleUpdateName = (event) => {
        event.preventDefault();
        setIsSavingName(true);
        const name = event.target.name.value;
        onUpdateName({id, name}).then(() => {
            handleToggleEditName();
            setIsSavingName(false);
        });
    };

    return isLoading ? (
        <LoadingSpinner />
    ) : (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper elevation={1} className={classes.paper}>
                        {isEditingName
                            ? (
                                <form onSubmit={handleUpdateName} className={classes.nameForm}>
                                    {isSavingName && <LoadingSpinner />}
                                    <TextField
                                        autoFocus
                                        name={"name"}
                                        label={"Navn"}
                                        defaultValue={name}
                                        required
                                        className={classes.nameFormTextField}
                                    />
                                    <Button
                                        type={"submit"}
                                        color={"primary"}
                                        variant={"contained"}
                                    >
                                        Gem
                                    </Button>
                                </form>
                            ) : (
                                <Typography
                                    variant={"h6"}
                                    className={classes.headline}
                                    onClick={handleToggleEditName}
                                >
                                    {name}
                                </Typography>
                            )}
                        {timeOfDayList.map(({ id, name }) => (
                            <TimeSlot
                                key={id}
                                id={id}
                                name={name}
                                timeOfDayList={timeOfDayList}
                            />
                        ))}
                    </Paper>
                </Grid>
                <FoodEntriesNutrition foodEntries={foodEntries} showWater />
                {foodEntries.length > 0 && <CopyMealPlanTemplateToMealPlan mealPlanTemplateId={id} />}
                <Grid item xs={12}>
                    <div className={classes.actionButtonsContainer}>
                        <Link to="/skabeloner" className={classes.link}>
                            <Button variant={"contained"}>Tilbage</Button>
                        </Link>
                        <Button variant={"contained"} color={"secondary"} onClick={handleDelete}>Slet kostplan</Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    );

}

export default withStyles(styles)(MealPlanTemplate);
