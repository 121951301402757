import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MealPlanTemplates from './Meal-plan-templates';
import * as actions from './actions';

function mapStateToProps(state) {
    return {
        templates: state.mealPlanTemplate.list,
    };
}


const mapDispatchToProps = (dispatch) => {
    const {
        onLoadTemplates,
        onCleanupTemplates,
    } = actions;
    return bindActionCreators({
        onLoad: onLoadTemplates,
        oncleanup: onCleanupTemplates,
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(MealPlanTemplates);
