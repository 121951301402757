import * as types from './action-types';
import api from '../../api';

export const onLoadMeals = () => (dispatch) => {
    dispatch({
        type: types.UPDATE,
        payload: {
            isLoading: true,
        },
    });
    // Fetch all meals on load
    api.get('meal').then(({ data }) => {
        dispatch({
            type: types.UPDATE,
            payload: {
                list: data,
                isLoading: false,
            },
        });
    });
};

export const onLoadMeal = ({ id }) => (dispatch) => {
    dispatch({
        type: types.UPDATE,
        payload: {
            isLoading: true,
        },
    });
    api.get(`meal/${id}`).then(({ data }) => {
        dispatch({
            type: types.UPDATE,
            payload: {
                chosen: data,
                isLoading: false,
            },
        });
    });
};

export const onCreateMeal = ({ name }) => (dispatch) => {
    dispatch({
        type: types.UPDATE,
        payload: {
            isLoading: true,
        },
    });
    const data = {
        name,
    };
    return api.post('meal',data);
};

export const onUpdateName = ({ id, name }) => (dispatch) => {
    const data = {
        name,
    };

    return new Promise((resolve) => {
        api.put(`meal/${id}`, data).then(() => {
            dispatch({
                type: types.UPDATE_MEAL_NAME,
                name,
                id,
            });
            resolve();
        });
    });
};

export const onDeleteMeal = ({ id }) => (dispatch) => {
    dispatch({
        type: types.UPDATE,
        payload: {
            isLoading: true,
        },
    });
    return api.delete(`meal/${id}`);
};

export const onCleanupMeals = () => ({
    type: types.UPDATE,
    payload: {
        list: [],
    },
});

export const onCleanupMeal = () => ({
    type: types.UPDATE,
    payload: {
        chosen: undefined,
    },
});

export const onCreateFoodEntries = ({mealId, foodEntries}) => (dispatch) => {
    const data = {
        mealId,
        foodEntries: foodEntries.map(({food, weight, food_measure_id}) => ({
            foodId: food.id,
            weight,
            food_measure_id,
        }))
    };
    return new Promise((resolve) => {
        api.post('mealFoodEntries', data).then((response) => {
            dispatch({
                type: types.ADD_FOOD_ENTRIES,
                foodEntries: response.data,
            });
            resolve();
        });
    });
};

export const onDeleteFoodEntry = ({ id }) => (dispatch) => {
    api.delete(`mealFoodEntries/${id}`).then(() => {
        dispatch({
            type: types.DELETE_FOOD_ENTRY,
            id,
        });
    });
};

export const onUpdateFoodEntry = ({ id, weight }) => (dispatch) => {
    const data = {};
    if (weight) {
        data.weight = weight;
    }

    api.put(`mealFoodEntries/${id}`, data).then(({ data }) => {
        dispatch({
            type: types.UPDATE_FOOD_ENTRY,
            foodEntry: {
                ...data,
            }
        });
    });
};

export const onCopyMealToMealPlan = ({mealId, date, timeOfDayId, amount}) => (dispatch) => {
    dispatch({
        type: types.UPDATE,
        payload: {
            isCopyingMeal: true,
        },
    });

    const data = {
        mealId,
        date,
        timeOfDayId,
        amount,
    };

    return new Promise((resolve) => {
        api.post('meal/copyToMealPlan', data).then(() => {
            dispatch({
                type: types.UPDATE,
                payload: {
                    isCopyingMeal: false,
                },
            });
            resolve();
        });
    });
};

export const onCopyMealToMealPlanTemplate = ({mealId, amount, mealPlanTemplateId, timeOfDayId}) => (dispatch) => {
    dispatch({
        type: types.UPDATE,
        payload: {
            isCopyingMealToTemplate: true,
        },
    });

    const data = {
        mealPlanTemplateId,
        mealId,
        amount,
        timeOfDayId,
    };

    return new Promise((resolve) => {
        api.post('meal/copyToMealPlanTemplate', data).then(() => {
            dispatch({
                type: types.UPDATE,
                payload: {
                    isCopyingMealToTemplate: false,
                },
            });
            resolve();
        });
    });
};